
const theDate = new Date()

export default function Footer() {
  return (
    <div className="bg-optiblue p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 justify-center lg:flex lg:justify-between p-12">
            <div className="w-full text-white md:w-1/2 lg:w-2/5">
                <h1 className="text-xl text-white font-bold mb-4">Optisoft</h1>
                <p className="w-5/6 lg:w-4/5">
                We pride ourselves with providing the best service possible by fusing creativity with technology.
                </p>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/4">
                <h1 className="text-lg font-bold mb-4 text-white">Quick Links</h1>
                <div className="flex flex-col justify-around text-md">
                    <a href='/about' className="text-white mb-2 hover:text-gray-400">About Us</a>
                    <a href='/contact' className="text-white mb-2 hover:text-gray-400">Contact</a>
                    <a href='/faq' className="text-white mb-2 hover:text-gray-400">FAQ</a>
                    <a href="https://school.optisoft.ng" className="text-white mb-2 hover:text-gray-400">Training</a>
                    <a href='#' className="text-white mb-2 hover:text-gray-400">Privacy Policy</a>
                </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/4">
                <h1 className="text-lg font-bold mb-4 text-white">Contact Us</h1>
                <div>
                    <div className="flex">
                        <div className="w-4 mr-2">
                            <img src="/images/icons/mail.png" alt="Software Development In Lagos" />
                        </div>
                        <div>
                          <a href="mailto:info@optisoft.ng">
                            <p className="text-white">
                                Info@optisoft.ng
                            </p>
                          </a>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-4 mr-2">
                            <img src="/images/icons/phone.png" alt="Software Development In Lagos" />
                        </div>
                        <div>
                          <a href="tel:+2347088957111">
                          <p className="text-white">
                              (+234) 708 895 7111
                          </p>
                          </a>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-4 mr-2">
                            <img src="/images/icons/location.png" alt="Software Development In Lagos"/>
                        </div>
                        <p className="w-4/5 text-white">
                            4th Floor, Polaris Building, 30 Marina Lagos
                        </p>
                    </div>
                </div>
            </div>

            <div className="w-full md:w-1/2 lg:w-1/5">
                <h1 className="text-lg font-bold mb-4 text-white">Follow Us</h1>
                <div className="flex items-center">
                    <a href=' https://twitter.com/optisoftng' className="mr-4">
                        <img className="w-5" src="/images/icons/twitter.png" alt="Software Development In Lagos"/>
                    </a>
                    <a href=' https://instagram.com/optisoftng?igshid=YmMyMTA2M2Y=' className="mr-4">
                        <img className="w-5" src="/images/icons/insta.png" alt="Software Development In Lagos"/>
                    </a>
                    <a href='https://www.facebook.com/optisoftdev/' className="mr-4">
                        <img className="w-5" src="/images/icons/facebook.png" alt="Software Development In Lagos"/>
                    </a>
                    <a href='https://www.linkedin.com/company/optisoft-solutions/' className="mr-4">
                        <img className="w-5" src="/images/icons/linkedin.png" alt="Software Development In Lagos"/>
                    </a>
                </div>
            </div>
        </div>
        <div className="text-center">
            <h5 className="p-8 text-white">© Copyright {theDate.getFullYear()} Optisoft Technology Company (Nig.) Ltd</h5>
        </div>
    </div>
  );
}
