
  import Navbar from "../../globalComponents/Navbar";
  import Footer from "../../globalComponents/Footer";
  import FAQBanner from "./components/FAQBanner";
  import CTASection from '../Home/components/CTASection'
  import { PlusCircleOutlined } from '@ant-design/icons';
  import { Collapse, Select } from 'antd';
  const { Panel } = Collapse;

  export default function FAQ() {

    const genExtra = () => (
        <PlusCircleOutlined className="text-lg fonr-bold"/>
      );

    return (
      <>
        <Navbar/>
        <div>
            <FAQBanner/>
        </div>    
        <div className="mt-12 mb-12 p-2 lg:pl-40 lg:pr-40">
            <div className="">
                <Collapse>
                    <Panel className="p-2" showArrow={false} header="What software development methodologies do we use?" key="1" extra={genExtra()}>
                    <div>
                      All our development and software testing processes are running while adhering to highly effective methodologies including Agile, Scrum, DevOps and other methodologies to meet the highest KPIs for your project.
                      We document all the operations required to handle the development of your digital product and measure them according to the following key parameters:
                      <br/>
                      ·         Activity description;
                      <br/>
                      ·         Milestone documentation;
                      <br/>
                      ·         Project scope statement;
                    </div>
                    </Panel>
                    <Panel className="p-2" showArrow={false} header="How do we ensure privacy and confidentiality?" key="2" extra={genExtra()}>
                    <div>
                      We sign NDAs and Confidentiality Agreements with you. All our employees are full-time employees and are bound by company Confidentiality and Non-Disclosure clauses.
                    </div>
                    </Panel>
                    <Panel className="p-2" showArrow={false} header="What's our project management style?" key="3" extra={genExtra()}>
                    <div>We adopt an agile project management style.</div>
                    </Panel>
                    <Panel className="p-2" showArrow={false} header="How long does it take to start your project development?" key="4" extra={genExtra()}>
                    <div>
                    we review your project requirements; get them analyzed and then inform you of the timeline for each milestone.
                    </div>
                    </Panel>
                    <Panel className="p-2" showArrow={false} header="How do we guarantee product quality?" key="5" extra={genExtra()}>
                    <div>
                    We evaluate the result at each stage, we test our work (we conduct both development and quality tests), we present it to you and we apply your feedback so you know you get what you are paying for.
                    </div>
                    </Panel>
                    <Panel className="p-2" showArrow={false} header="Do you get any product support?" key="6" extra={genExtra()}>
                    <div>
                    Yes, we provide quality support service to all projects based on the term agreed by the dedicated support team to your product.
                    </div>
                    </Panel>
                    <Panel className="p-2" showArrow={false} header="What control do I have over project development?" key="7" extra={genExtra()}>
                    <div>
                      You would retain full control over all stages in the development life 
                      cycle of your project. You can monitor the process through regular calls 
                      and meetings, and track the status and exercise of every task. Within a set of 
                      meetings scheduled throughout the whole development cycle, we would inform you of 
                      the progress in regard to the project roadmap. We divide our entire work into sprints 
                      for ease of tracking and so you can make changes in the scope at any stage.                    
                    </div>
                    </Panel>
                </Collapse>
            </div>
        </div>
        <CTASection/>
        <div>
          <Footer/>
        </div>
      </>
    );
  }
  
  
  