
import '../../style.css'
  
export default function Others() {

  return (
    <div className='flex flex-col lg:flex-row justify-center items-center p-8'>
        <div className='w-full lg:relative lg:left-20 lg:w-1/2'>
            <img className='w-full' src='/images/banners/others1.svg' alt="Software Development In Lagos"/>
            <p className='w-full lg:w-3/5 text-xs lg:text-start text-center lg:left-20 relative bottom-10 lg:bottom-20'>
            Optisoft has a proud history of utilizing experience to ensure optimal client satisfaction by applying professionalism to every project.
            </p>
        </div>
        <div className='w-full lg:w-1/2 lg:relative lg:right-40'>
            <div className='relative bottom-20 lg:bottom-0 lg:top-20'>
                <img className='w-full' src='/images/banners/others2.svg' alt="Software Development In Lagos"/>
            </div>
        </div>
    </div>
  );
}


