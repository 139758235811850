
import SingleServiceCard from "./components/SingleServiceCard";
import Navbar from "../../globalComponents/Navbar";
import Footer from "../../globalComponents/Footer";
import CTA from "../Home/components/CTASection";

export default function Digital() {
    const info = {
        title: "Digital Marketing",
        desc: "Optisoft is a leading digital marketing solutions provider in Lagos, Nigeria. We assist businesses in creating and developing digital marketing strategies that helps increase product visibility, enhance customer experience and expand sales target ",
        desc2: "We deliver cutting-edge solutions for global organizations and technology start-ups.  Since 2015 we have been helping established brands, companies, and start-ups, reimage their business through digital representation using the latest technologies.",
        webLink: 'https://finman.live',
        image: "/images/banners/digital.png"
    }
  return (
    <div className="bg-slate-200">
        <Navbar/>
        <div className="p-8">
            <SingleServiceCard service={info}/>
        </div>
        <div className="p-4 relative bottom-10">
            <div className="text-center">
                <h1 className="text-lg font-bold  mt-12 text-optiblue">What We Do</h1>
                <div className="flex justify-center">
                <p className="max-w-5xl">
                We offer a suite of digital marketing services that are designed to increase brand awareness, engagement and conversions 
                </p>
                </div>
            </div>
            <div className="mb-12 mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-10">
                <div className="flex justify-center">
                <div className="w-80  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-4">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Digital Marketing Company In Lagos" src="/images/icons/icon1.png"/>
                    </div>
                    <h1>Social Media Marketing</h1>
                    <p>
                    We assist brands in developing and managing an online presence on social media platforms by creating and sharing quality content that increases engagement and conversion for their business
                    </p>
                </div>
                </div>

                <div className="flex justify-center">
                <div className="w-80  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Digital Marketing Company In Lagos" src="/images/icons/icon2.png"/>
                    </div>
                    <h1>Search Engine Optimization</h1>
                    <p>
                    We provide SEO solutions for small and large-scale companies. We specialize in ensuring that your website ranks in the SERP by implementing best new practices for improved visibility in organic search results and increased rate of conversion. With our extensive experience and expertise in digital marketing,we are able to offer you the highest level of customer satisfaction.
                    </p>
                </div>
                </div>

                <div className="flex justify-center">
                <div className="w-80  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Digital Marketing Company In Lagos" src="/images/icons/icon3.png"/>
                    </div>
                    <h1>Email Marketing</h1>
                    <p>
                    We offer robust email marketing management to both small and large-scale business enterprises to increase lead generation and boost sales targets using professional tools and actionable insights that help increase retention thereby making clients’ businesses more profitable.
                    </p>
                </div>
                </div>

            </div>
        </div>
        <CTA/>
        <Footer/>
    </div>
  );
}

