
import '../../style.css'
  
  export default function CTA() {

    return (
      <div className="mt-4 mb-8 p-8 bg-optiblue">
        <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="flex justify-center w-full md:w-4/5 lg:w-1/2">
              <p className="text-white w-full mt-4 lg:mt-0 lg:w-4/5">
              Need custom process automation, software development, tech outsourcing, consulting services and software solutions in Nigeria?
              </p>
            </div>
            <div className="w-full lg:w-1/2 flex justify-center">
            <a href='/contact'>
                <button className="font-bold hover:text-black text-white hover:bg-white ctabtn border border-white p-2 mt-2 rounded-md">
                  Get in touch
                </button>
            </a>
            </div>
        </div>
      </div>
    );
  }
  
  
  