
  const blueBg = {
    background: "#2D3A84"
  }

export default function ContactCard() {
  return (
    <>
      <div style={blueBg} className="flex flex-col lg:flex-row justify-evenly items-center p-8">
          <div className="w-full lg:w-1/2">
              <p className="text-white text-center lg:text-start lg:w-4/5">
              Optisoft has a proud history of utilizing experience to ensure optimal client satisfaction by applying professionalism to every project.
              </p>
          </div>
          <div className="mt-4 lg:mt-0 w-full lg:w-1/2 flex justify-center">
          <form action="mailto:info@optisoft.ng" method="POST" enctype="text/plain" name="EmailFormWebSite" className="w-full md:w-3/5 bg-white p-8">
            <div>
              <h5 className="text-xs">Name</h5>
              <input className="w-full border border-black rounded-md p-1" type='text' name="name"/>
            </div>
            <div>
              <h5 className="text-xs">Email</h5>
              <input className="w-full border border-black rounded-md p-1" type='email' name="email"/>
            </div>
            <div>
              <h5 className="text-xs">Message</h5>
              <textarea className="w-full border border-black rounded-md p-1" rows='4'>

              </textarea>
            </div>
            <div className="flex justify-center mt-4">
              <button type="submit" className="w-44 bg-orange-400 p-2 text-white">Send</button>
            </div>
          </form>
          </div>
      </div>
    </>
  );
}

