
import SingleServiceCard from "./components/SingleServiceCard";
import Navbar from "../../globalComponents/Navbar";
import CTA from "../Home/components/CTASection";
import Footer from "../../globalComponents/Footer";

export default function IT() {
    const info = {
        title: "IT Outsourcing and Consulting",
        desc: " We provide you with highly trained, skilled technology professionals competent in handling projects of different sizes and complexities, with a commitment to success. ",
        desc2: "Our core areas of specialization cuts across Process Automation, Software Engineering, Project management and Consulting, Core banking applications, Mobile application development, Machine learning systems, Online payment integration, POS, and Business Service Applications.",
        
        webLink: 'https://finman.live',
        image: "/images/banners/it.png"
    }
  return (
    <div className="bg-slate-200">
        <Navbar/>
        <div className="p-8">
            <SingleServiceCard service={info}/>
        </div>
        <div className="p-4 relative bottom-10">
            <div className="text-center">
                <h1 className="text-lg font-bold  mt-12 text-optiblue">Why Use Our Outsourcing Team ?</h1>
                <div className="flex justify-center">
                <p className="lg:max-w-3xl">
                Our team of experts provides advanced technology innovations, operations and management to your organization. <br/>
                We help you get the most out of your IT operations by working closely with your company/staff to define a strategy that maximizes performance, productivity and satisfaction. 
                </p>
                </div>
            </div>
            <div className="flex justify-center">
            <div className="lg:max-w-4xl mb-12 mt-12 grid grid-cols-1 lg:grid-cols-3 justify-center items-center gap-10">
                


                <div className="flex justify-center">
                <div className="w-80  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="IT Outsourcing Company In Lagos" src="/images/icons/icon2.png"/>
                    </div>
                    <h1>Cloud automation consulting services</h1>
                    <p>
                    We provide cloud automation services used in integrating effective business development and operational solutions within an organization. We proffer the best security solutions tailored specifically to your infrastructure to remove repetitive tasks, save clients’ time and ensure efficiency in business.
                    </p>
                </div>
                </div>

                <div className="flex justify-center">
                <div className="w-80  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="IT Outsourcing Company In Lagos" src="/images/icons/icon3.png"/>
                    </div>
                    <h1>AI Solutions</h1>
                    <p>
                    We specialize in developing artificial intelligence solutions like Chatbox, to enable great user experience by assisting them with a variety of tasks. Advanced innovations such as digital personal assistants and chatbots can be used to transform customer experience and communication, leading to an overall boost in customer satisfaction.  
                    </p>
                </div>
                </div>

                <div className="flex justify-center">
                <div className="w-80  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="IT Outsourcing Company In Lagos" src="/images/icons/icon3.png"/>
                    </div>
                    <h1>UI and UX consulting</h1>
                    <p>
                    At Optisoft, we begin by understanding your needs, market demand, goals, and visions, before working together with you to build a prototype with detailed aspects including UI components, layouts, and information hierarchy, then validate it with the end-user to reach a perfect product and interface.
                    </p>
                </div>
                </div>

            </div>
            </div>

            <div className="flex justify-center">
            <div className="lg:max-w-4xl mb-12 mt-12 grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-10">
                

                <div className="flex justify-center">
                <div className="w-80  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="IT Outsourcing Company In Lagos" src="/images/icons/icon3.png"/>
                    </div>
                    <h1>Project management services</h1>
                    <p>
                    For technological projects within your organization, we provide management services from initiation and planning, to execution, and monitoring. It is our culture to deliver a project within an agreed time frame. We conduct thorough research and feasibility studies based on the project, and our experienced project managers and consultants assist in ensuring successful execution
                    </p>
                </div>
                </div>

                <div className="flex justify-center">
                <div className="w-80  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-4">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="IT Outsourcing Company In Lagos" src="/images/icons/icon1.png"/>
                    </div>
                    <h1>Custom software development</h1>
                    <p>
                    We provide skilled UI-UX designers, front-end, and back-end developers, capable of developing software for specific users, functions, and organizations. Our team of professional technical engineers are skilled with advanced best coding practices and expertise in making architectural decisions relating to custom software development. 
                    {/* We are competent in developing custom mobile and web applications including banking and inventory management systems amongst others. */}
                    </p>
                </div>
                </div>

            </div>
            </div>
            
            
        </div>
        <CTA/>
        <Footer/>
    </div>
  );
}

