
  import Navbar from "../../globalComponents/Navbar";
  import Footer from "../../globalComponents/Footer";
  import Banner from "./components/Banner";
  import { Tabs } from 'antd';
  import ProductCard from "./components/ProductCard";
  import ContactCard from "../../globalComponents/ContactCard";
  import './style.css'


  const bodyStyle = {
    backgroundImage: "url('/images/bg/bodybg.png')",
    backgroungSize: "contain"
  }

  
  export default function Products() {
    return (
      <>
        <Navbar/>
        <div  style={bodyStyle}>
        <Banner/>
        <div className="flex flex-col justify-center text-center items-center mt-12 mb-12">
            <h1 className="text-2xl font-bold">Products</h1>
            <p className="w-full lg:w-2/5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris porta id varius consequat. Leo sit sollicitudin erat egestas interdum suscipit.
            </p>
        </div>
        <Tabs className="tabspadding-lg" defaultActiveKey="1" centered type="card" size="large">
            <Tabs.TabPane className='flex justify-center items-center' tab="Fintech" key="1">
                <div className="max-w-7xl p-4 grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-10">
                    <div className="flex justify-center">
                        <ProductCard/>
                    </div>
                    <div className="flex justify-center">
                        <ProductCard/>
                    </div>
                    <div className="flex justify-center">
                        <ProductCard/>
                    </div>
                    <div className="flex justify-center">
                        <ProductCard/>
                    </div>
                </div>
            </Tabs.TabPane>
            <Tabs.TabPane className='p-4' tab="ERP" key="2">
            Content of Tab Pane 2
            </Tabs.TabPane>
            <Tabs.TabPane className='p-4' tab="E-Commerce" key="3">
            Content of Tab Pane 3
            </Tabs.TabPane>
        </Tabs>
        </div>
        <ContactCard/>
        <div>
          <Footer/>
        </div>
      </>
    );
  }
  
  
  