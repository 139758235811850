const whySectionStyle = {
    minHeight: '90vh',
    padding: '1rem'
  };

export default function WhySection(){

    return(
        <section className="mt-12">
          <div className="flex flex-col lg:flex-row justify-center items-center p-4">
            <div className="w-full lg:w-1/2 text-center lg:text-left">
              <h3 className="font-bold text-4xl">Why Optisoft</h3>
              <p className="w-full lg:w-3/5 text-md">
              Clients say “we are the best” but all we do is give our best. At Optisoft, Your Business + Our Skills = Excellence. Our team of skilled engineers got you covered.
              </p>
              <button className="mb-8 w-30 lg:mb-0 font-bold text-white bg-optiblue p-2 rounded-md">Get in Touch</button>
            </div>


            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
              <div className="w-72 shadow-2xl card hover:bg-optiblue hover:text-white p-4  text-center flex flex-col items-center">
                <div>
                  <img alt="Software Development In Lagos" className="bg-optiblue p-2 rounded-full whiteimg" src="/images/icons/expwt.png"/>
                  <img className="bg-optiblue p-2 rounded-full blackimg" src="/images/icons/exp.png"/>
                </div>
                <h4 className="">Experienced</h4>
                <p className="text-sm">
                We have a proven history of developing technical products/services for global organizations. Our team of skilled software engineers, product designers and digital marketers help define your goals, and create effective strategies to best suit your needs.
                </p>
              </div>

              <div className="w-72 shadow-2xl card hover:bg-optiblue hover:text-white p-4  text-center flex flex-col items-center">
                <div>
                  <img className="bg-optiblue p-2 rounded-full whiteimg" src="/images/icons/priceswt.png"/>
                  <img className="bg-optiblue p-2 rounded-full blackimg" src="/images/icons/prices.png"/>
                </div>
                <h4 className="">Affordable Prices</h4>
                <p className="text-sm">
                At Optisoft Technology Company, we pride ourselves on providing premium process solutions services at the most affordable rates.
                </p>
              </div>

              <div className="w-72 shadow-2xl card hover:bg-optiblue hover:text-white p-4  text-center flex flex-col items-center">
                <div>
                  <img className="bg-optiblue p-2 rounded-full whiteimg" src="/images/icons/supportwt.png"/>
                  <img className="bg-optiblue p-2 rounded-full blackimg" src="/images/icons/support.png"/>
                </div>
                <h4 className="">Support Team</h4>
                <p className="text-sm">
                We supply capable hands to provide users with help and advice about clients’ products.
                </p>
              </div>

              <div className="w-72 shadow-2xl card hover:bg-optiblue hover:text-white p-4  text-center flex flex-col items-center">
                <div>
                  <img className="bg-optiblue p-2 rounded-full whiteimg" src="/images/icons/profwt.png"/>
                  <img className="bg-optiblue p-2 rounded-full blackimg" src="/images/icons/prof.png"/>
                </div>
                <h4 className="">Professionalism</h4>
                <p className="text-sm">
                Optisoft Technology Company is committed to delivering our clients' optimal professional and personalized service.
                </p>
              </div>
            </div>
          </div>
        </section>
    )
}
