
import SingleServiceCard from "./components/SingleServiceCard";
import Navbar from "../../globalComponents/Navbar";
import Footer from "../../globalComponents/Footer";
import CTA from "../Home/components/CTASection";

export default function Mobile() {
    const info = {
        title: "Mobile Application Development",
        desc:"Optisoft Technology is recognized by the Nigerian technology awards (NiTA) as the 2017 and 2018 winner for best mobile app development Company in Nigeria.",
        
        desc2: "We bring your tech product ideas to life by making sure that your vision is achieved from inception to actualization",
        webLink: 'https://finman.live',
        image: "/images/banners/mobile.png"
    }
  return (
    <div className="bg-slate-200">
        <Navbar/>
        <div className="p-8">
            <SingleServiceCard service={info}/>
        </div>
        <div className="p-4 relative bottom-10">
            <div className="text-center">
                <h1 className="text-lg font-bold  mt-12 text-optiblue">Why You Need Optisoft Mobile Application Team </h1>
                <div className="flex flex-col items-center justify-center">
                <p className="lg:max-w-3xl">
                Whether you’re looking for a new custom mobile app or revamping an existing app, 
                we have the right team, experience and technology stack to deliver great results. 
                With our approach to cross-platform apps, we create an experience for the end-user 
                that makes it easy and enjoyable to use thereby not just building an app but a business 
                for you.
                </p>
                <p className="lg:max-w-3xl">
                Our expertise cuts across all businesses such as Fintech / Digital banking app, 
                E-commerce app, Utility & Lifestyle app, E-hailing app, Logistics app, Food & drinks, 
                Travel & Tours etc. We also offer mobile app development for small, medium and large-scale businesses.
                </p>
                </div>
            </div>

            <div className="flex justify-center">

            <div className="lg:max-w-5xl mb-12 mt-12 grid grid-cols-1 lg:grid-cols-2 justify-center items-center gap-10">
                <div className="flex justify-center">
                <div className="w-full g:w-5/6  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-4">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Mobile App Development Company In Lagos" src="/images/icons/icon1.png"/>
                    </div>
                    <h1>User Experience and Interface Design</h1>
                    <p>
                    Smartphone users demand intuitiveness, ease of use, and simplicity. A crucial strategy for success we deploy in developing an app is ensuring that the interface design isn’t cluttered with unnecessary features. User-friendly mobile apps guarantee consumer satisfaction. We understand the app will be an extension of your brand; hence, we aim not only to create but build relationships with consumers.
                    </p>
                </div>
                </div>

                <div className="flex justify-center">
                <div className="w-full g:w-5/6  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Mobile App Development Company In Lagos" src="/images/icons/icon3.png"/>
                    </div>
                    <h1>Offline Access</h1>
                    <p>
                    The ideal scenario is that every user has consistent internet access wherever they are. Unfortunately, data coverage fluctuates, especially when someone travels to an out-of-range location. Online access may be essential for some types of apps – such as those that offer products and services. However, if your business can benefit from providing offline access to consumers, we can build you a NATIVE APP. A native app uses the phone’s storage, thus allowing offline access.
                    </p>
                </div>
                </div>

                <div className="flex justify-center">
                <div className="w-full g:w-5/6  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Mobile App Development Company In Lagos" src="/images/icons/icon2.png"/>
                    </div>
                    <h1>Mobile OS Versatility</h1>
                    <p>
                    We have the expertise and experience building apps compatible with both Android and iOS, which allows you to easily implement your mobile technology strategy. Our development team has worked on many applications for both platforms so you can rest assured that we’re able to develop the appropriate integration for you. Our apps are memory-efficient, perform well on multiple devices, and are easy to maintain.
                    </p>
                </div>
                </div>

                

                <div className="lg:relative lg:bottom-2 flex justify-center">
                <div className="w-full g:w-5/6  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Mobile App Development Company In Lagos" src="/images/icons/icon2.png"/>
                    </div>
                    <h1>Device Screen Responsiveness</h1>
                    <p>
                    Apart from the features, functionality, and user-friendly design, we also consider varying mobile screen sizes. Nowadays, mobile devices tend to be bigger – almost similar to a mini-tablet. But, not everyone likes big-screen phones. Our development team ensures that every user has a consistent experience regardless of screen size.
                    </p>
                </div>
                </div>

            </div>

            </div>
        </div>
        <CTA/>
        <Footer/>
    </div>
  );
}

