
import '../../style.css'
  
  export default function Clients() {
    return (
      <div className="bg-slate-50 p-12">
        <h3 className="text-center font-bold text-3xl mt-12 mb-12">Our Clients</h3>
        <div className="grid grid-cols-2 md:grid-cols-5 lg:grid-cols-5 gap-10 mb-8 justify-center items-center">
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/cummins.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/rosabon.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/netcom.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/union.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/ncf.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/grand.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/fairship.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/p.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/bridge.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/boxbybox.png" alt="Software Development In Lagos"/>
            </div>
        </div>

        <hr/>

        <div className="grid grid-cols-2 md:grid-cols-6 lg:grid-cols-6 gap-10 justify-center mt-8 items-center">
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/kadick.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5" alt="Software Development In Lagos">
                <img src="/images/clients/etuk.png"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/moto.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/cashluck.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/trs.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/3ds.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/andora.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/errand.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/fitc.png" alt="Software Development In Lagos"/>
            </div>
            <div className="w-full lg:w-2/5">
                <img src="/images/clients/irisk.png" alt="Software Development In Lagos"/>
            </div>
        </div>
      </div>
    );
  }
  
  
  