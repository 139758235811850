
import SingleServiceCard from "./components/SingleServiceCard";
import Navbar from "../../globalComponents/Navbar";
import CTA from "../Home/components/CTASection";
import Footer from "../../globalComponents/Footer";

export default function ProductDesign() {
    const info = {
        title: "Product Design",
        desc: "We are one of the leading firms in Nigeria’s tech sector that offers the best product design services to companies and individuals. Our team of UI/UX designers have years of experience and are industry veterans who have designed products for companies in many industries. ",
        desc2: "We have a strong understanding of design terms and user experience in different sectors both digital and non-digital, which allows us to create innovative solutions that tackle the specific needs of your business.",
        
        webLink: 'https://finman.live',
        image: "/images/banners/product.png"
    }
  return (
    <div className="bg-slate-200">
        <Navbar/>
        <div className="p-8">
            <SingleServiceCard service={info}/>
        </div>
        <div className="p-4">
            <div className="text-center mb-12">
                <h1 className="text-lg font-bold  mt-12 text-optiblue">What our Product design team offer</h1>
                <div className="flex justify-center">
                <p className="max-w-5xl">
                Our product design team are expert in creating awesome products and will get you from early-stage product ideation to having a working and valuable product that gives users a great experience. We help you turn your vision into a reality through rapid and effective product design. Our creative thinking, innovative process and collaborative work with you will get you to the next level of great products.
                </p>
                </div>
            </div>
            <div className="flex justify-center">

            <div className="lg:max-w-5xl mb-12 mt-12 grid grid-cols-1 lg:grid-cols-2 justify-center items-center gap-10">
                
                <div className="flex justify-center">
                <div className="w-full g:w-5/6  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Product Design Company In Lagos" src="/images/icons/icon2.png"/>
                    </div>
                    <h1>User experience (UX) design</h1>
                    <p>
                    UX design is the process our design team uses to create products that provide meaningful, relevant and effective experiences for users. The goal of user experience design is to create a harmonious product experience where the user’s needs are met and they have a positive overall experience, from the moment of choice until their next action on your product. We take this responsibility very seriously, working in close collaboration with you to understand how you see your users and how you want them to interact with their products/brands.
                    </p>
                </div>
                </div>

                

                <div className="lg:relative lg:top-2 flex justify-center">
                <div className="w-full g:w-5/6  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Product Design Company In Lagos" src="/images/icons/icon2.png"/>
                    </div>
                    <h1>User interface (UI) design</h1>
                    <p>
                    User interface design is the process of establishing a framework for interacting with an interface, including both physical and digital elements. It’s the emotional experience that your customers get from interacting with your business; an intuitive and effective user interface design allows the customer to complete their customer journey rapidly. Design can be a critical aspect of your brand's success if you have a great product or service and with our design team, you can have it all.
                    </p>
                </div>
                </div>

                <div className="lg:relative lg:bottom-4 flex justify-center">
                <div className="w-full g:w-5/6  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Product Design Company In Lagos" src="/images/icons/icon3.png"/>
                    </div>
                    <h1>Information Architecture (IA)  Design</h1>
                    <p>
                    Information Architecture (IA) focuses on organising, structuring and labelling content in an effective and sustainable way.  We offer IA that can strengthen user engagement, increase search performance, improve navigation, and ultimately create a better user experience.
                    </p>
                </div>
                </div>
                
                
                <div className="flex justify-center">
                <div className="w-full g:w-5/6  bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-4">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Product Design Company In Lagos" src="/images/icons/icon1.png"/>
                    </div>
                    <h1>Process design</h1>
                    <p>
                    Whether you’re creating a new project for the first time or looking to refine an existing one, Our team takes a holistic process to design your products from starting with a creative brief to conducting graphic design research, brainstorming design ideas, and review processes at different stages of your project before presenting the final product for approval.
                    </p>
                </div>
                </div>

                

                

            </div>

            </div>
        </div>
        <CTA/>
        <Footer/>
    </div>
  );
}

