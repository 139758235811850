import { Dropdown, Menu, Space, Modal } from 'antd';
import { useEffect, useState, useRef } from "react";
import ReCAPTCHA  from "react-google-recaptcha"
import emailjs from '@emailjs/browser';

  const blueBg = {
    background: "#2D3A84"
  }

  export default function SingleServiceCard(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
      setIsModalOpen(true);
    };

    const captchaRef = useRef(null)

    const form = useRef();
    const handleSubmit = (e) => {
      e.preventDefault(); // Prevents default refresh by the browser
      emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_QUOTE_TEMPLATE_ID, form.current , process.env.REACT_APP_USER_ID)
      .then((result) => {
      alert("Quote Sent, We will get back to you shortly");
      },
      (error) => {
      alert("An error occurred, Please try again", error.text);
      });
      };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

  return (
    <div className="bg-optislate flex flex-col lg:flex-row justify-center items-center p-2 lg:p-8">
        <div className="w-full lg:w-1/2">
            <div className="mb-8">
                <h1 className="text-2xl font-bold">{props.service.title}</h1>
                <div className="bg-optiorange p-1 w-40"></div>
            </div>
            <p className="text-center lg:text-start lg:w-4/5">
              {props.service.desc}
            </p>
            {props.service.desc2?
            <p className="text-center mt-4 lg:text-start lg:w-4/5">
                {props.service.desc2}
            </p>
            :null}
            {/* {props.service.webLink? */}
            <div className='flex justify-center lg:justify-start mb-8'>
              <div className="w-40 text-center flex flex-col mt-4 justify-center">
                <a  onClick={showModal}  className="text-md font-bold bg-optiblue rounded-md text-white p-4">Request Service</a>
              </div>
            </div>
            {/* :null} */}
        </div>
        <div className="mt-4 lg:mt-0 w-full lg:w-1/2 flex justify-center">
            <img alt="Software Development Company In Lagos" className="w-4/5 md:w-3/5" src={props.service.image}/>
        </div>


        <div>
          <div >
            <Modal footer={null} title="Request Service" open={isModalOpen} onCancel={handleCancel}>
              <div className="p-1 flex justify-center">
                  <div className="w-full">
                      <div className="mb-8 text-center lg:text-start">
                          <h5 className="text-lg font-bold">Get In Touch, Your Idea Plus Our Skills Will bring An Excellent Result </h5>
                      </div>
                      <form ref={form} >
                      <div className="mb-8">
                          <h5 className="text-xs text-gray-400">Your Name</h5>
                          <input className="w-full border border-gray-400 p-1" type='text' name="name"/>
                      </div>
                      <div className="mb-8">
                          <h5 className="text-xs text-gray-400">Email Address</h5>
                          <input className="w-full border border-gray-400 p-1" type='email' name="email"/>
                      </div>
                      <div className="mb-8">
                          <h5 className="text-xs text-gray-400">Phone Number</h5>
                          <input className="w-full border border-gray-400 p-1" type='text' name="phone"/>
                      </div>
                      <div className="mb-8">
                          <h5 className="text-xs text-gray-400">Project Type</h5>
                          <select className="w-full border border-gray-400 p-1" name="project">
                            <option value="Mobile Application Development">Mobile Application Development</option>
                            <option value="Software Development">Software Development</option>
                            <option value="Product Design">Product Design</option>
                            <option value="Web and Ecommerce Development">Web and Ecommerce Development</option>
                            <option value="Digital Marketing">Digital Marketing</option>
                            <option value="IT Outsourcing and Consulting">IT Outsourcing and Consulting</option>
                            <option value="Others">Others</option>
                          </select>
                      </div>
                      <div className="mb-8">
                          <h5 className="text-xs text-gray-400">Product Type</h5>
                          <select className="w-full border border-gray-400 p-1" name="product">
                            <option value="EZone">E-Zone</option>
                            <option value="Cube700">Cube 700</option>
                            <option value="EasyGo">Easy Go</option>
                            <option value="Spydov">Spydov</option>
                            <option value="Finman">Finman</option>
                            <option value="Realm">Realm</option>
                            <option value="Others">Others</option>
                          </select>
                      </div>
                      <div className="mb-8">
                          <h5 className="text-xs text-gray-400">Brief Description</h5>
                          <textarea className="w-full border border-gray-400 p-1" rows='4' name="description">

                          </textarea>
                      </div>

                      <div className="flex justify-end">
                        <ReCAPTCHA  sitekey="6LfrHI8iAAAAAGjLllWA5nAARqDflaakAnoGvCOD" ref={captchaRef} />
                      </div>

                        <div className="flex justify-end mt-8">
                            <button onClick={(e) => {
                              e.preventDefault()
                              setIsModalOpen(false)}}  className="w-20 border border-optiblue p-2 text-optiblue rounded-md mr-8">Cancel</button>
                            <button onClick={handleSubmit} className="w-20 bg-optiblue p-2 text-white rounded-md">Submit</button>
                        </div>
                      </form>
                  </div>
              </div>
            </Modal>
          </div>
        </div>
    </div>
  );
  }
