
import SingleProductCard from './components/SingleProductCard'
import Navbar from "../../globalComponents/Navbar";
import CTASection from '../Home/components/CTASection'
import Footer from "../../globalComponents/Footer";

export default function Realm() {
    const product = {
        title: "Realm",
        webLink: 'https://realmonline.live',
        desc: "Realm is a user-friendly property management software for property managers, building developers, real-estate managers, and landlords. ",
        desc2: "Realm takes complete control of every aspect of property management. It makes property management simpler, more efficient and more organized. A fully integrated property management  system that collects rent, tracks maintenance requests, and sends invoices.",
        image: "/images/banners/realm.png"
    }
  return (
    <div className="">
        <Navbar/>
        <div className="p-8">
            <SingleProductCard product={product}/>
        </div>
        <div className="text-center mb-12">
            <h1 className="text-lg font-bold text-violet-700">Features</h1>
            <p>Here are some of our advantages</p>
        </div>
        <div className="mb-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-10">
            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Property Management System" className="w-8 mb-2" src="/images/icons/cable.png"/>
                <h1>Accounting</h1>
                <p>
                Manage your accounts from inflow and outflow of rent payments. 
                Realm allows tenants to make payments directly, by bank transfer, 
                to the owners’ or property managers’ accounts and allow property managers 
                to monitor the payment. Keep all your transactions in one place
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Property Management System" className="w-8 mb-2" src="/images/icons/elect.png"/>
                <h1>Billing & Invoicing</h1>
                <p>
                Generate simple and easy bill and invoice payments on rent, maintenance, and more. 
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Property Management System" className="w-8 mb-2" src="/images/icons/data.png"/>
                <h1>Property Management</h1>
                <p>
                Whether you are a property manager, landlord, estate manager, 
                Realm includes all the features you need to make your operation 
                the most efficient it has ever been. From property information to 
                financial accounting to maintenance to standard reports and so on.
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Property Management System" className="w-8 mb-2" src="/images/icons/cable.png"/>
                <h1>Maintenance Management</h1>
                <p>
                When things are broken, Realm assists property managers to receive, 
                monitor, and complete work orders. Realm will allow property managers 
                to directly outsource the request to third-party vendors and schedule repairs.
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Property Management System" className="w-8 mb-2" src="/images/icons/elect.png"/>
                <h1>Document Management</h1>
                <p>
                Securely store and share important files such as lease agreements, photos, and other documents with your renters and owners.
                </p>
            </div>
            </div>

        </div>
        <CTASection/>
        <Footer/>
    </div>
  );
}

