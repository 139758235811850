  import { useRef, useState } from 'react'
  import Navbar from "../../globalComponents/Navbar"
  import Footer from "../../globalComponents/Footer"
  import ReCAPTCHA  from "react-google-recaptcha"
  import emailjs from '@emailjs/browser';

  const blueBg = {
    background: "#2D3A84"
  }

  
  export default function Contact() {
    
  const captchaRef = useRef(null)
  const [msg, setmessage] = useState({
    name: '',
    email: '',
    message: ''
  })
  const form = useRef();
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current , process.env.REACT_APP_USER_ID)
    .then((result) => {
    alert("Message Sent, We will get back to you shortly"); 
    setmessage({
        name: '',
        email: '',
        message: ''
      })
    },
    (error) => {
    alert("An error occurred, Please try again", error.text);
    });
    };

    return (
      <>
        <Navbar/>
        <div>

            <div className="p-8 flex flex-col lg:flex-row justify-center items-center">
                <div className="flex justify-center">
                    <div className="w-5/6">
                        <img src="/images/banners/contact.png" alt="Software Development In Lagos"/>
                    </div>
                </div>

                <div className="mt-4 lg:mt-0 w-1/2  flex justify-center">
                    <div className="lg:w-full">
                        <form ref={form} className="w-5/6 p-2 md:p-8">
                            <div className="text-center lg:text-start mb-8">
                                <h5 className="text-xl text-violet-800 font-bold">Get In Touch With Us</h5>
                                <p className="text-sm">Get In Touch With Us</p>
                            </div>

                            <div>
                                <h5 className="text-xs">Name</h5>
                                <input className="w-full border border-black p-1"
                                value={msg.name}
                                onChange={(e) => setmessage({...msg, name:e.target.value})} 
                                type='text' name="name" required/>
                            </div>
                            <div>
                                <h5 className="text-xs">Email</h5>
                                <input className="w-full border border-black p-1" 
                                value={msg.email}
                                onChange={(e) => setmessage({...msg, email:e.target.value})} 
                                type='email' name="email" required/>
                            </div>
                            <div>
                                <h5 className="text-xs">Phone</h5>
                                <input className="w-full border border-black p-1" 
                                value={msg.phone}
                                onChange={(e) => setmessage({...msg, phone:e.target.value})} 
                                type='text' name="phone" required/>
                            </div>
                            <div>
                                <h5 className="text-xs">Message</h5>
                                <textarea className="w-full border border-black p-1" 
                                value={msg.message}
                                onChange={(e) => setmessage({...msg, message:e.target.value})} 
                                rows='4' name="message" required>

                                </textarea>
                            </div>
                            <div>
                            
                            <ReCAPTCHA  sitekey="6LfrHI8iAAAAAGjLllWA5nAARqDflaakAnoGvCOD" ref={captchaRef} />
                            </div>
                            <div className="flex justify-center mt-4">
                                <button onClick={handleSubmit} className="w-full bg-optiblue p-2 text-white">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="p-8 flex flex-col md:flex-row justify-between items-center">
                <div className="flex">
                    <div>
                        <img className="w-5" src="/images/icons/location.png" alt="Software Development In Lagos"/>
                    </div>
                    <p className="text-violet-800 font-semibold">4th Floor, Polaris Building, 30 Marina Lagos</p>
                </div>
                <div className="">
                    <p>You can see us at our office Location</p>
                </div>
            </div>

            <div className="mb-8">
            <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d908.8782614112714!2d3.388556265769587!3d6.451127945021136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s4th%20Floor%2C%20Polaris%20Building%2030%20Marina%20Lagos!5e0!3m2!1sen!2sng!4v1664058409507!5m2!1sen!2sng" width="100%" height="600" style={{border:"0", width:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        <div>
          <Footer/>
        </div>
      </>
    );
  }
  
  
  