const bannerStyle = {
    backgroundImage: "url('/images/bg/servicebg.png')",
    backgroundSize: "cover"
}
export default function Banner() {
  return (
    <div style={bannerStyle} className="h-60 flex justify-center items-center">
      <h1 className="text-white font-bold text-2xl">Our Services</h1>
    </div>
  );
}

