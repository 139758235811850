
import SingleProductCard from './components/SingleProductCard'
import Navbar from "../../globalComponents/Navbar";
import Footer from "../../globalComponents/Footer";
import CTASection from '../Home/components/CTASection'

export default function Work400() {
    const product = {
        title: "Work 400",
        webLink: 'https://work400.com',
        desc: "Work400 is a competency assessment platform that is used to bridge the gap between organizations and their employees' true potential.",
        // desc2: "It provides a simple yet effective way for business stakeholders such as sales and operations teams to track product inventory, generate accurate documentation, and perform efficient operations while maintaining and enhancing B2B/B2C relationships.",
        image: "/images/banners/skills.png"
    }
  return (
    <div className="">
        <Navbar/>
        <div className="p-8">
            <SingleProductCard product={product}/>
        </div>
        <div className="text-center mb-12">
            <h1 className="text-lg font-bold text-violet-700">Why Work 400</h1>
            <div className="flex justify-center">
            <p className="max-w-5xl">
            The platform provides a comprehensive analysis of employees' skills and capabilities, allowing organizations to identify areas where they need to invest in their employees.
            </p>
            </div>
        </div>
        <div className="grid grid-cols-1 mb-12 md:grid-cols-1 lg:grid-cols-3 justify-center items-center gap-10">
            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Inventory Management System" className="w-8 mb-2" src="/images/icons/cable.png"/>
                <h1>Skills Assessment</h1>
                <p>
                Work400 is an excellent tool that allows employees to view their strengths and weaknesses and develop targeted development plans to maximize their potential.
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Inventory Management System" className="w-8 mb-2" src="/images/icons/elect.png"/>
                <h1>IMPROVED DECISION MAKING</h1>
                <p>
                Work400 is a valuable resource for employers, providing them with insights into their workforce. This information can help them make informed decisions about hiring, training, and career development.
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Inventory Management System" className="w-8 mb-2" src="/images/icons/data.png"/>
                <h1>ENHANCED PRODUCTIVITY</h1>
                <p>
                Work400 provides companies with the opportunity to unlock the full potential of their employees, thereby creating a more successful and productive workplace. It is a powerful asset that helps businesses achieve their objectives and foster a more productive and successful environment.
                </p>
            </div>
            </div>

            {/* <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Inventory Management System" className="w-8 mb-2" src="/images/icons/cable.png"/>
                <h1>Sales</h1>
                <p>
                This module shows the sales a user has made, also invoices can be generated and downloaded for each sale made.  
                </p>
            </div>
            </div> */}

        </div>
        <CTASection/>
        <Footer/>
    </div>
  );
}

