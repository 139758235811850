
  import Navbar from "../../globalComponents/Navbar";
  import Footer from "../../globalComponents/Footer";
  import Banner from "./components/Banner";

  const bodyStyle = {
    backgroundImage: "url('/images/bg/bodybg.png')",
    backgroungSize: "cover"
  }
  
  export default function Services() {
    return (
      <>
        <Navbar/>
        <Banner/>
        <div style={bodyStyle} className="">
            <section className="min-h-screen p-4">
            <div className="mt-12 flex flex-col lg:flex-row justify-center items-center">
                <div className="w-4/5 md:w-2/5 lg:w-1/4">
                    <img alt="Software Development In Lagoss" className="w-full" src="/images/banners/service1.png"/>
                </div>
                <div className="w-full mt-8 lg:mt-0 lg:w-1/3 text-center lg:text-start lg:ml-8">
                    <h4 className="font-bold text-xl">Software Development</h4>
                    <p className="">
                    Optisoft is a leading software development company in Nigeria and Africa, We are at the forefront of software development locally and Globally.

                    For this reason, We build powerful software for our clients base on their specifications, without cutting out the resource.

                    No doubt, Optisoft uses modern technologies to produces a stable, scalable and secured software that can stand with any software produced around the world.
                    </p>
                </div>
            </div>

            <div className="mt-20 flex flex-col-reverse lg:flex-row justify-center items-center">
                <div className="w-full mt-8 lg:mt-0 lg:w-1/3 text-center lg:text-start">
                    <h4 className="font-bold text-xl">Mobile Application Development</h4>
                    <p className="">
                    Optisoft is currently the 2017 and 2018 winners of Nigeria technology awards for best mobile app development company in Nigeria. We turn your dreams and ideas into reality. Our apps development focuses on the target business and users. We don't just build apps, we build businesses. Lets discuss your app developmetn requirements today.

                    Firstly, Mobile Application development has shifted from what it was to what it is now due to technology upgrade.
                    </p>
                </div>
                <div className="w-4/5 md:w-2/5 lg:w-1/4 lg:ml-8">
                    <img alt="Software Development In Lagoss" className="w-full" src="/images/banners/service2.png"/>
                </div>
            </div>

            <div className="mt-12 flex flex-col lg:flex-row justify-center items-center">
                <div className="w-4/5 md:w-2/5 lg:w-1/4">
                    <img alt="Software Development In Lagoss" className="w-full" src="/images/banners/service3.png"/>
                </div>
                <div className="w-full mt-8 lg:mt-0 lg:w-1/3 text-center lg:text-start lg:ml-8">
                    <h4 className="font-bold text-xl">Digital Marketing</h4>
                    <p className="">
                    Optisoft offers Digital Marketing packages to our clients coupled with lots of benefits and support.

                    Truely, It can be difficult getting your products out there without publicity.  We help you do the hard-work while you watch your sales grow.

                    No doubt, Our marketing plans make all forms of business get their products more sales.

                    It's simple, Contact us lets know your plans and you're ready!
                    </p>
                </div>
            </div>

            <div className="mt-20 flex flex-col-reverse lg:flex-row justify-center items-center">
                <div className="w-full mt-8 lg:mt-0 lg:w-1/3 text-center lg:text-start">
                    <h4 className="font-bold text-xl">IT Outsourcing and Consulting</h4>
                    <p className="">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Nunc, nunc porta scelerisque vitae et, vitae nisi condimentum. 
                    Sed eget orci, malesuada mi. Pulvinar accumsan gravida mauris, 
                    sit purus ipsum semper. At cras feugiat felis eget faucibus pretium 
                    in condimentum nam. Eget enim urna et, non phasellus vitae sit. Et 
                    scelerisque malesuada euismod nunc.
                    </p>
                </div>
                <div className="w-4/5 md:w-2/5 lg:w-1/4 lg:ml-8">
                    <img alt="Software Development In Lagoss" className="w-full" src="/images/banners/service4.png"/>
                </div>
            </div>

            <div className="mt-12 flex flex-col lg:flex-row justify-center items-center">
                <div className="w-4/5 md:w-2/5 lg:w-1/4">
                    <img alt="Software Development In Lagoss" className="w-full" src="/images/banners/service5.png"/>
                </div>
                <div className="w-full mt-8 lg:mt-0 lg:w-1/3 text-center lg:text-start lg:ml-8">
                    <h4 className="font-bold text-xl">Web and Ecommerce Development</h4>
                    <p className="">
                    Optisoft eCommerce solution is optimized to give your client products maximum visibility on search engines and through the internet.

                    Our team have good experience with major eCommerce platforms available i.e Magento, Woocemerce, VirtualMart, Shpoify, Open Cart e.t.c.

                    We can build a customize platform for clients that have large operations and heavy user base to manage with lots of products.
                    </p>
                </div>
            </div>

            <div className="mt-20 flex flex-col-reverse lg:flex-row justify-center items-center">
                <div className="w-full mt-8 lg:mt-0 lg:w-1/3 text-center lg:text-start">
                    <h4 className="font-bold text-xl">Product Design</h4>
                    <p className="">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Nunc, nunc porta scelerisque vitae et, vitae nisi condimentum. 
                    Sed eget orci, malesuada mi. Pulvinar accumsan gravida mauris, 
                    sit purus ipsum semper. At cras feugiat felis eget faucibus pretium 
                    in condimentum nam. Eget enim urna et, non phasellus vitae sit. Et 
                    scelerisque malesuada euismod nunc.
                    </p>
                </div>
                <div className="w-4/5 md:w-2/5 lg:w-1/4 lg:ml-8">
                    <img alt="Software Development In Lagoss" className="w-full" src="/images/banners/service6.png"/>
                </div>
            </div>
            </section>
          <Footer/>
        </div>
      </>
    );
  }
  
  
  