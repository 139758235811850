
  const blueBg = {
    background: "#2D3A84"
  }

  export default function SingleProductCard(props) {
  return (
    <div className="bg-optislate flex flex-col lg:flex-row justify-center items-center p-2 lg:p-8">
        <div className="w-full lg:w-1/2">
            <div className="mb-8">
                <h1 className="text-2xl font-bold">{props.product.title}</h1>
                <div className="bg-optiorange p-1 w-20"></div>
            </div>
            <p className="text-center lg:text-start lg:w-4/5">
            {props.product.desc}
            </p>
            {props.product.desc2?
            <p className="text-center mt-4 lg:text-start lg:w-4/5">
                {props.product.desc2}
            </p>
            :null}
            {props.product.appLink?
            <div className="w-full flex flex-col md:flex-row justify-center lg:justify-start items-center">
              <div className="m-4 lg:m-0"><a href={props.product.appLink.appstore}><img alt="Software Development In Lagos" className="w-32" src='/images/icons/applestore.png'/></a></div>
              <div className="m-4 lg:m-0 lg:ml-4"><a href={props.product.appLink.playstore}><img alt="Software Development In Lagos" className="w-32" src='/images/icons/playstore.png'/></a></div>
            </div>
            :null}
            {/* {props.product.webLink?
            <div className="w-full flex flex-col  justify-center">
              <a href={props.product.webLink} className="text-md font-bold text-optiorange">Learn More</a>
            </div>
            :null} */}
        </div>
        <div className="mt-4 lg:mt-0 w-full lg:w-1/2 flex justify-center">
            {props.product.image?
            <img alt="Software Development In Lagos" className="w-4/5 md:w-3/5" src={props.product.image}/>
            :null}
            {props.product.video?
              <div className="bg-optiblue p-2">
                <video controls autoplay="autoplay" muted>
                  <source src={props.product.video} poster={props.product.thumbnail} type="video/mp4"/>
                  Your browser does not support the HTML5 Video element.
                </video>
              </div>
            :null}
        </div>
    </div>
  );
  }
