import './App.css';
import 'antd/dist/antd.css';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import Home from './pages/Home';
import Services from './pages/Services';
import Products from './pages/Products';
import ProductDesign from './pages/Services/ProductDesign';
import Mobile from './pages/Services/Mobile';
import Digital from './pages/Services/Digital';
import IT from './pages/Services/IT';
import Web from './pages/Services/Web';
import SingleProducts from './pages/Products/SingleProduct';
import Software from './pages/Services/Software';
import About from './pages/About';
import Contact from './pages/Contact';
import EasyGo from './pages/Products/EasyGo';
import Work400 from './pages/Products/Work400'
import Cube from './pages/Products/Cube'
import Ezone from './pages/Products/Ezone'
import Finman from './pages/Products/Finman';
import Realm from './pages/Products/Realm';
import Spydov from './pages/Products/Spydov';
import FAQ from './pages/About/FAQ';
// Routing 
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/faq",
    element: <FAQ/>,
  },
  {
    path: "/services",
    element: <Services/>,
  },
  {
    path: "/products",
    element: <Products/>,
  },
  {
    path: "/single-product",
    element: <SingleProducts/>,
  },
  {
    path: "/easygo",
    element: <EasyGo/>,
  },
  {
    path: "/cube",
    element: <Cube/>,
  },
  {
    path: "/ezone",
    element: <Ezone/>,
  },
  {
    path: "/work400",
    element: <Work400/>,
  },
  {
    path: "/finman",
    element: <Finman/>,
  },
  {
    path: "/realm",
    element: <Realm/>,
  },
  {
    path: "/spydov",
    element: <Spydov/>,
  },
  {
    path: "/about",
    element: <About/>,
  },
  {
    path: "/contact",
    element: <Contact/>,
  },
  {
    path: "/product",
    element: <ProductDesign/>,
  },
  {
    path: "/software",
    element: <Software/>,
  },
  {
    path: "/mobile",
    element: <Mobile/>,
  },
  {
    path: "/digital",
    element: <Digital/>,
  },
  {
    path: "/it",
    element: <IT/>,
  },
  {
    path: "/web",
    element: <Web/>,
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
