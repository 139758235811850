
import SingleProductCard from './components/SingleProductCard'
import Navbar from "../../globalComponents/Navbar";
import CTASection from '../Home/components/CTASection'
import Footer from "../../globalComponents/Footer";

export default function Spydov() {
    const product = {
        title: "Spydov",
        webLink: 'https://spydov.com/',
        desc: "Spydov is designed to assist businesses with real-time monitoring of their assets. ",
        desc2: "It covers all operational activities of a company, such as location view, moves tracking, geo-fencing/tracing, mobile device monitoring, pictures/images, screen grabs, call logs, etc.",
        image: "/images/banners/spydov.png"
    }
  return (
    <div className="">
        <Navbar/>
        <div className="p-8">
            <SingleProductCard product={product}/>
        </div>
        {/* <div className="text-center mb-12">
            <h1 className="text-lg font-bold text-violet-700">Why Spydov</h1>
            <p>Here are some of our advantages</p>
        </div> */}
        {/* <div className="mb-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-10">
            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Real Time Tracking and Monitoring Software" className="w-8 mb-2" src="/images/icons/cable.png"/>
                <h1>Cable TV Subscription</h1>
                <p>
                    Lorem ipsum dolor sit amet, 
                    consectetur adipiscing elit. Nunc, 
                    nunc porta scelerisque vitae et, vitae 
                    nisi condimentum. Sed 
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Real Time Tracking and Monitoring Software" className="w-8 mb-2" src="/images/icons/elect.png"/>
                <h1>Electricity Bills</h1>
                <p>
                    Lorem ipsum dolor sit amet, 
                    consectetur adipiscing elit. Nunc, 
                    nunc porta scelerisque vitae et, vitae 
                    nisi condimentum. Sed 
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Real Time Tracking and Monitoring Software" className="w-8 mb-2" src="/images/icons/data.png"/>
                <h1>Data Subscription</h1>
                <p>
                    Lorem ipsum dolor sit amet, 
                    consectetur adipiscing elit. Nunc, 
                    nunc porta scelerisque vitae et, vitae 
                    nisi condimentum. Sed 
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Real Time Tracking and Monitoring Software" className="w-8 mb-2" src="/images/icons/cable.png"/>
                <h1>Recharge airtime</h1>
                <p>
                    Lorem ipsum dolor sit amet, 
                    consectetur adipiscing elit. Nunc, 
                    nunc porta scelerisque vitae et, vitae 
                    nisi condimentum. Sed 
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Real Time Tracking and Monitoring Software" className="w-8 mb-2" src="/images/icons/elect.png"/>
                <h1>Transfer between registered users</h1>
                <p>
                    Lorem ipsum dolor sit amet, 
                    consectetur adipiscing elit. Nunc, 
                    nunc porta scelerisque vitae et, vitae 
                    nisi condimentum. Sed 
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Real Time Tracking and Monitoring Software" className="w-8 mb-2" src="/images/icons/data.png"/>
                <h1>Book a flight ticket, hotel</h1>
                <p>
                    Lorem ipsum dolor sit amet, 
                    consectetur adipiscing elit. Nunc, 
                    nunc porta scelerisque vitae et, vitae 
                    nisi condimentum. Sed 
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Real Time Tracking and Monitoring Software" className="w-8 mb-2" src="/images/icons/cable.png"/>
                <h1>Transport services and more</h1>
                <p>
                    Lorem ipsum dolor sit amet, 
                    consectetur adipiscing elit. Nunc, 
                    nunc porta scelerisque vitae et, vitae 
                    nisi condimentum. Sed 
                </p>
            </div>
            </div>
        </div> */}
        <CTASection/>
        <Footer/>
    </div>
  );
}

