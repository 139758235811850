
import Navbar from "../../globalComponents/Navbar";
import HomeCarousel from "./components/HomeCarousel";
import ProductIconGroup from "./components/ProductIconGroup";
import Clients from "./components/Clients";
import Testimonials from "./components/Testimonials";
import CTA from "./components/CTASection";
import Footer from "../../globalComponents/Footer";
import HomeServices from "./components/Services";
import WhySection from "./components/WhySection";
import Others from "./components/Others";

export default function Home() {
  return (
    <>
      <Navbar/>
      <div>
        <HomeCarousel/>
        {/* <ProductIconGroup/> */}
        <HomeServices/>
        <WhySection/>
        {/* <Testimonials/> */}
        <Others/>
        <Clients/>
        <CTA/>
        <Footer/>
      </div>
    </>
  );
}


