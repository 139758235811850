
import SingleServiceCard from "./components/SingleServiceCard";
import Navbar from "../../globalComponents/Navbar";
import CTA from "../Home/components/CTASection";
import Footer from "../../globalComponents/Footer";

export default function Software() {
    const info = {
        title: "Software Development",
        desc: "At Optisoft Technology Company, we are dedicated to helping you reach the top of your industry. Our software development services integrate the vision, resources and experience needed to meet your technology goals",
        // desc2: "At Optisoft Technology Company, we are dedicated to helping you reach the top of your industry. Our software development services integrate the vision, resources and experience needed to meet your technology goals",
        webLink: 'https://finman.live',
        image: "/images/banners/software.png"
    }
  return (
    <div className="bg-slate-200">
        <Navbar/>
        <div className="p-8">
            <SingleServiceCard service={info}/>
        </div>
        <div className="p-4 relative bottom-10">
            <div className="text-center">
                <h1 className="text-lg font-bold  mt-12 text-optiblue">What our Software Development team offers</h1>
                <div className="flex justify-center">
                <p className="max-w-3xl">
                We provide a team of client-focused, brilliant and talented engineers with a penchant for producing great work. We also offer training programs and consultancy services to assist clients in making better decisions as it concerns the development of their software and smooth business operation. 
                </p>
                </div>
            </div>

            
            <div className="mt-12 flex justify-center">
                <div className="p-8 bg-white flex flex-col items-center rounded-md lg:max-w-3xl" >
                    <div className="w-12 mb-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Software Development Company In Lagos" src="/images/icons/icon1.png"/>
                    </div>
                    <div>
                        <li className="mb-4"> Building/designing your brand and business identity, which could include logo development and stationery design. </li>
                        <li className="mb-4"> Developing a digital brochure to showcase your business products and service.</li>
                        <li className="mb-4"> Set up your social media channels with logos and profile page graphics.</li>
                        <li className="mb-4"> Developing a brochure-style website - including registering your stationery design. </li>
                        <li> Design a website and register the domain name.</li>
                    </div>
                </div>
            </div>
            {/* <div className="mb-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-10">
                <div className="flex justify-center">
                <div className="w-72 h-60 bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-4">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Software Development Company In Lagos" src="/images/icons/icon1.png"/>
                    </div>
                    <h1>User Research</h1>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Nunc, nunc porta scelerisque vitae et, vitae nisi condimentum. Sed 
                    </p>
                </div>
                </div>

                <div className="flex justify-center">
                <div className="w-72 h-60 bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Software Development Company In Lagos" src="/images/icons/icon2.png"/>
                    </div>
                    <h1>Neat Interface</h1>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Nunc, nunc porta scelerisque vitae et, vitae nisi condimentum. Sed 
                    </p>
                </div>
                </div>

                <div className="flex justify-center">
                <div className="w-72 h-60 bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Software Development Company In Lagos" src="/images/icons/icon3.png"/>
                    </div>
                    <h1>Protoyping</h1>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Nunc, nunc porta scelerisque vitae et, vitae nisi condimentum. Sed 
                    </p>
                </div>
                </div>

            </div> */}
        </div>
        <CTA/>
        <Footer/>
    </div>
  );
}

