
  import Navbar from "../../globalComponents/Navbar";
  import Footer from "../../globalComponents/Footer";
  import Banner from "./components/Banner";
  import CTASection from '../Home/components/CTASection'
  import TeamCard from "./components/TeamCard";

  const blueBg = {
    background: "#2D3A84"
  }


  const bodyStyle = {
    backgroundImage: "url('/images/bg/bodybg.png')",
    backgroungSize: "contain"
  }

  const mission = {
    backgroundImage: "url('/images/banners/mission.png')",
    backgroungSize: "cover",
    backgroundRepeat: "no-repeat"
  }

  const vision = {
    backgroundImage: "url('/images/banners/vision.png')",
    backgroungSize: "contain",
    backgroundRepeat: "no-repeat"
  }

  const goals = {
    backgroundImage: "url('/images/banners/goals.png')",
    backgroungSize: "contain",
    backgroundRepeat: "no-repeat"
  }


  export default function About() {
    return (
      <>
        <Navbar/>
        <div>
            <Banner/>
            <div style={bodyStyle} className="p-8">
                <div className="flex justify-center">
                <p className="lg:max-w-3xl text-center p-8">
                Optisoft Technology Company Ltd is a custom software
                services firm based in Lagos, Nigeria. We have extensive
                experience in diverse areas of software development and
                management. Our experienced staff of professionals has
                worked with a wide array of platforms, languages, and tools
                in the course of our various projects.
                </p>
                </div>

                <div className=" flex flex-col lg:flex-row justify-center items-center p-4 mb-12">
                    <div className="min-h-1/2 w-full md:w-3/5 lg:w-2/5">
                        <div style={mission} className='min-h-1/2 h-full flex justify-center items-center'>
                            <h1 className="text-white text-4xl font-bold">Our Mission</h1>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 text-center lg:text-start flex items-center">
                    <div className="p-8 w-full lg:w-4/5">
                        <p className="text-xl">
                        Understanding clients needs and implementing them using high-tech
                        software to guarantee optimal customer satisfaction
                        </p>
                    </div>
                    </div>
                </div>


                <div className="flex flex-col-reverse lg:flex-row justify-center items-center p-4 mb-12">
                    <div className="w-full lg:w-1/2 min-h-1/2 flex items-center">
                    <div className="p-8 relative bottom-10 lg:bottom-0 text-center lg:text-start w-full lg:w-4/5">
                        <p className="text-xl">
                        To be the number 1 technology development platform of choice in Africa
                        </p>
                    </div>
                    </div>
                    <div className="min-h-1/2 w-full md:w-3/5 lg:w-2/5 ">
                        <div style={vision} className='min-h-1/2 flex justify-center items-center'>
                            <h1 className="text-white text-4xl font-bold">Our Vision</h1>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-center items-center p-4">
                    <div className="relative bottom-24 lg:bottom-0 min-h-1/2 w-full md:w-3/5 lg:w-2/5">
                        <div style={goals} className='min-h-1/2 h-full flex justify-center items-center'>
                            <h1 className="text-white text-4xl font-bold">Our Values</h1>
                        </div>
                    </div>
                    <div className="relative bottom-24 lg:bottom-0 w-full lg:w-1/2 text-center lg:text-start min-h-1/2 flex items-center">
                    <div className="p-8 w-full lg:w-4/5">
                        <p className="text-xl">
                        <br/>
                        Excellent Products
                        <br/>
                        Innovation
                        <br/>
                        Service
                        <br/>
                        Convenience
                        <br/>
                        Technology

                        </p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="p-8 relative bottom-24 lg:bottom-0">
            <div style={blueBg} className='h-60 flex justify-center p-4'>
                <div className="text-center text-white">
                <h1 className="text-lg font-bold text-white">Meet our Team Members</h1>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-20 lg:grid-cols-3 relative bottom-20">
                <div className="flex justify-center">
                    <TeamCard details={{
                        name: "Andrew Ogochukwu Ughonu",
                        position: "CEO",
                        image: "/images/people/andrew.jpg"
                    }}/>
                </div>
                <div className="flex justify-center">
                    <TeamCard details={{
                        name: "Tony Ajekigbe",
                        position: "COO",
                        image: "/images/people/tony.jpg"
                    }}/>
                </div>
                <div className="flex justify-center">
                    <TeamCard details={{
                        name: "Chukwunonso Inoma",
                        position: "Head Sales/Marketing Department",
                        image: "/images/people/inoma.jpg"
                    }}/>
                </div>
            </div>

                <div className="grid gap-20 grid-cols-1 md:grid-cols-3 lg:grid-cols-3">
                    <div className="flex justify-center">
                        <TeamCard details={{
                            name: "Chioma Okoye J",
                            position: "Head Human Resources",
                            image: "/images/people/chioma.jpg"
                        }}/>
                    </div>
                    <div className="flex justify-center">
                        <TeamCard details={{
                            name: "Marona Kehinde",
                            position: "Administrative Manager",
                            image: "/images/people/kehinde.jpg"
                        }}/>
                    </div>
                    <div className="flex justify-center">
                        <TeamCard details={{
                            name: "Adaobi Ekwemozo",
                            position: "Product Manager",
                            image: "/images/people/ada.jpg"
                        }}/>
                    </div>
                </div>

            <div className="flex justify-center">

                <div className="lg:max-w-5xl mt-20 grid gap-20 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                    <div className="flex justify-center">
                        <TeamCard details={{
                            name: "Victor Ezeonwuka",
                            position: "Head Web Application Unit",
                            image: "/images/people/victor.jpg"
                        }}/>
                    </div>
                    <div className="flex justify-center">
                        <TeamCard details={{
                            name: "Sam Bassey",
                            position: "Head Mobile Application Unit",
                            image: "/images/people/sam.jpg"
                        }}/>
                    </div>
                    <div className="flex justify-center">
                        <TeamCard details={{
                            name: "Anosike Fidelis",
                            position: "Project Manager",
                            image: "/images/people/fidelis.jpg"
                        }}/>
                    </div>
                    <div className="flex justify-center">
                        <TeamCard details={{
                            name: "Shittu Oluwaseyi",
                            position: "Business Development Manager",
                            image: "/images/people/shittu.jpg"
                        }}/>
                    </div>
                </div>

            </div>


        </div> */}
        <CTASection/>
        <div>
          <Footer/>
        </div>
      </>
    );
  }
