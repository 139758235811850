import React from 'react';

const bgStyle = {
    backgroundImage: "url('/images/bg/homeservices.png')",
    backgroundSize: "cover"
  };


export default function HomeServices() {

  return (
  <section style={bgStyle} >
    <div className='flex flex-col items-center text-center p-8'>
        <h1 className='text-3xl font-bold'>Our Services</h1>
        <p className='w-full text-lg lg:w-2/5'>
        Optisoft has been helping established brands, companies, and start-ups, re-image their business through digital representation using the latest technologies since 2015
        </p>
    </div>
        <div className='flex justify-center'>
        <div className=' lg:max-w-5xl p-4 lg:p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 justify-center'>
            <div className='flex justify-center'>
                <div className='service w-64 h-64 text-center p-8 rounded-full border border-black hover:border-optiblue flex flex-col justify-center items-center'>
                    <div className=' text-lmd mb-4 font-bold'>
                        Software Development
                    </div>
                    <div className='hidden service-details'>
                        <p className='text-sm'>
                        Our software development services integrate the vision, resources and experience needed to meet your technology goals
                        </p>
                        <div className='flex justify-center items-center text-optiblue'>
                        <a href='/software' className='text-optiblue' >
                        See More
                        </a>
                        <div><img className='ml-2' src='/images/icons/more.png' alt="Software Development In Lagos"/></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center'>
                <div className='service w-64 h-64 text-center p-8 rounded-full border border-black hover:border-optiblue flex flex-col justify-center items-center'>
                    <div className=' text-lmd mb-4 font-bold'>
                        Mobile Application Development
                    </div>
                    <div className='hidden service-details'>
                        <p className='text-xs'>
                        Optisoft Technology is recognized by the Nigerian technology awards (NiTA) as the best mobile app development company in Nigeria, 2017/2018
                        </p>
                        <div className='flex justify-center items-center text-optiblue'>
                        <a href='/mobile' className='text-optiblue' >
                        See More
                        </a>
                        <div><img className='ml-2' src='/images/icons/more.png' alt="Software Development In Lagos"/></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center'>
                <div className='service w-64 h-64 text-center p-8 rounded-full border border-black hover:border-optiblue flex flex-col justify-center items-center'>
                    <div className=' text-lmd mb-4 font-bold'>
                        Digital Marketing
                    </div>
                    <div className='hidden service-details'>
                        <p className='text-sm'>
                        Optisoft is a leading digital marketing solutions provider in Lagos, Nigeria.
                        </p>
                        <div className='flex justify-center items-center text-optiblue'>
                        <a href='/digital' className='text-optiblue' >
                        See More
                        </a>
                        <div><img className='ml-2' src='/images/icons/more.png' alt="Software Development In Lagos"/></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center'>
                <div className='service w-64 h-64 text-center p-8 rounded-full border border-black hover:border-optiblue flex flex-col justify-center items-center'>
                    <div className=' text-lmd mb-4 font-bold'>
                        IT Outsourcing and Consulting
                    </div>
                    <div className='hidden service-details'>
                        <p className='text-sm'>
                        We provide you with highly trained, skilled technology professionals with global experience.
                        </p>
                        <div className='flex justify-center items-center text-optiblue'>
                        <a href='/it' className='text-optiblue' >
                        See More
                        </a>
                        <div><img className='ml-2' src='/images/icons/more.png' alt="Software Development In Lagos"/></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center'>
                <div className='service w-64 h-64 text-center p-8 rounded-full border border-black hover:border-optiblue flex flex-col justify-center items-center'>
                    <div className=' text-lmd mb-4 font-bold'>
                        Web and Ecommerce Development
                    </div>
                    <div className='hidden service-details'>
                        <p className='text-sm'>
                        Optisoft's e-commerce solution is optimized to give our clients' products maximum visibility
                        </p>
                        <div className='flex justify-center items-center text-optiblue'>
                        <a href='/web' className='text-optiblue' >
                        See More
                        </a>
                        <div><img className='ml-2' src='/images/icons/more.png' alt="Software Development In Lagos"/></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center'>
                <div className='service w-64 h-64 text-center p-8 rounded-full border border-black hover:border-optiblue flex flex-col justify-center items-center'>
                    <div className=' text-lmd mb-4 font-bold'>
                        Product Design
                    </div>
                    <div className='hidden service-details'>
                        <p className='text-sm'>
                        Our team of ui/ux designers have years of experience and have designed products for companies in many industries/sectors
                        </p>
                        <div className='flex justify-center items-center text-optiblue'>
                        <a href='/product' className='text-optiblue' >
                        See More
                        </a>
                        <div><img className='ml-2' src='/images/icons/more.png' alt="Software Development In Lagos"/></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
  </section>
)}
