
import SingleProductCard from './components/SingleProductCard'
import Navbar from "../../globalComponents/Navbar";
import Footer from "../../globalComponents/Footer";
import CTASection from '../Home/components/CTASection'

export default function Cube() {
    const product = {
        title: "Cube 700",
        webLink: 'https://cube700.net',
        desc: "Cube 700 is a Saas product designed to enable businesses monitor product flow through various distribution channels. ",
        desc2: "It provides a simple yet effective way for business stakeholders such as sales and operations teams to track product inventory, generate accurate documentation, and perform efficient operations while maintaining and enhancing B2B/B2C relationships.",
        image: "/images/banners/cube.png"
    }
  return (
    <div className="">
        <Navbar/>
        <div className="p-8">
            <SingleProductCard product={product}/>
        </div>
        <div className="text-center mb-12">
            <h1 className="text-lg font-bold text-violet-700">Why Cube 700</h1>
            <div className="flex justify-center">
            <p className="max-w-5xl">
            Cube 700 is designed to enhance the organization of sales activity through automated and streamlined pick-pack-ship features, in addition to integration with your existing ERP. Benefits of Cube 700 includes but are not restricted to:
            </p>
            </div>
        </div>
        <div className="grid grid-cols-1 mb-12 md:grid-cols-1 lg:grid-cols-3 justify-center items-center gap-10">
            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Inventory Management System" className="w-8 mb-2" src="/images/icons/cable.png"/>
                {/* <h1>Analysis</h1> */}
                <p>
                analyzing key business processes that contribute towards the success or failure of the company;
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Inventory Management System" className="w-8 mb-2" src="/images/icons/elect.png"/>
                {/* <h1>Products</h1> */}
                <p>
                enabling B2B companies identify weaknesses within their supply chains, helping them recognize where/how they can improve their performance by optimizing distribution channels; 
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Inventory Management System" className="w-8 mb-2" src="/images/icons/data.png"/>
                {/* <h1>Branches</h1> */}
                <p>
                offering information about customers through robust customer profiles and allowing them view how people from various countries interact with their brands by analyzing data from multiple channels simultaneously. 
                </p>
            </div>
            </div>

            {/* <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Inventory Management System" className="w-8 mb-2" src="/images/icons/cable.png"/>
                <h1>Sales</h1>
                <p>
                This module shows the sales a user has made, also invoices can be generated and downloaded for each sale made.  
                </p>
            </div>
            </div> */}

        </div>
        <CTASection/>
        <Footer/>
    </div>
  );
}

