

export default function ProductCard() {
  return (
    <div className="bg-optigray p-4 rounded-md w-5/6 mb-12">
      <div className="flex justify-center w-full">
        <img alt="Software Development In Lagos" src="/images/banners/product.png"/>
      </div>
      <div>
        <h1 className="text-xl mt-4">Investment management </h1>
        <p className="text-sm mt-2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Nunc, nunc porta scelerisque vitae et, vitae nisi condimentum. 
        Sed eget orci, malesuada n phasellus vitae sit. Et scelerisque 
        malesuada euismod nunc.
        </p>
        <div className='text-lg mt-8 flex items-center text-optiblue'>
          See More
          <div><img alt="Software Development In Lagos" className='ml-2' src='/images/icons/more.png'/></div>
        </div>
      </div>
    </div>
  );
}

