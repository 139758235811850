
import SingleServiceCard from "./components/SingleServiceCard";
import Navbar from "../../globalComponents/Navbar";
import CTA from "../Home/components/CTASection";
import Footer from "../../globalComponents/Footer";

export default function Web() {
    const info = {
        title: "Web and E-Commerce Development",
        desc: "Optisoft's e-commerce solution is optimized to give our clients' products maximum visibility on search engines and through the internet. Our team of professionals have good experience with major e-commerce platforms such as Magento, Woo-commerce, VirtualMart, Shopify, Open Cart etc. ",
        desc2: "Our vast supply of resources affords us the ability to develop custom E-commerce platforms for clients, both large and small scales, with multiple processes and a heavy userbase to manage with lots of products.",
        webLink: 'https://finman.live',
        image: "/images/banners/web.png"
    }
  return (
    <div className="bg-slate-200">
        <Navbar/>
        <div className="p-8">
            <SingleServiceCard service={info}/>
        </div>
        <div className="p-4 relative bottom-10">

            <div className="mt-12 flex justify-center">
                <div className="p-8 bg-white flex flex-col items-center rounded-md lg:max-w-3xl" >
                    <div className="w-12 mb-12  h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Web and Ecommerce Development Company In Lagos" src="/images/icons/icon1.png"/>
                    </div>
                    <div>
                        <p className="mb-4  text-center">
                        Above 70% of conversion and traffic rates come from search engines. 
                        SEO is a very important optimization needed for good e-commerce applications 
                        and our team of competent digital marketers is capable of researching, composing, 
                        and strategically positioning your brand to rank high above others on search engine results pages. 
                        </p>

                        <p className="mb-4  text-center">
                        Also, User experience is very vital for an e-commerce platform to thrive. 
                        Mobile responsiveness for phones, tablets and various viewing devices is equally 
                        a priority to us as this is a determining factor for optimal client satisfaction.
                        </p>
                        
                        <p className="text-center">
                        Lastly, as part of sales improvements for our client's products we 
                        recommend developing a mobile application for Android and IOS.
                        </p>
                    </div>
                </div>
            </div>
            {/* <div className="mb-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-10">
                <div className="flex justify-center">
                <div className="w-72 h-60 bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-4">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Web and Ecommerce Development Company In Lagos" src="/images/icons/icon1.png"/>
                    </div>
                    <h1>User Research</h1>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Nunc, nunc porta scelerisque vitae et, vitae nisi condimentum. Sed 
                    </p>
                </div>
                </div>

                <div className="flex justify-center">
                <div className="w-72 h-60 bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Web and Ecommerce Development Company In Lagos" src="/images/icons/icon2.png"/>
                    </div>
                    <h1>Neat Interface</h1>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Nunc, nunc porta scelerisque vitae et, vitae nisi condimentum. Sed 
                    </p>
                </div>
                </div>

                <div className="flex justify-center">
                <div className="w-72 h-60 bg-white shadow-2xl rounded-md flex flex-col justify-center items-center text-center p-2">
                    <div className="w-12 h-12 flex justify-center mb-2 bg-optiblue rounded-full p-2">
                        <img alt="Web and Ecommerce Development Company In Lagos" src="/images/icons/icon3.png"/>
                    </div>
                    <h1>Protoyping</h1>
                    <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Nunc, nunc porta scelerisque vitae et, vitae nisi condimentum. Sed 
                    </p>
                </div>
                </div>

            </div> */}
        </div>
        <CTA/>
        <Footer/>
    </div>
  );
}

