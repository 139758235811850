
import SingleProductCard from './components/SingleProductCard'
import Navbar from "../../globalComponents/Navbar";
import CTASection from '../Home/components/CTASection'
import Footer from "../../globalComponents/Footer";

export default function EasyGo() {
    const product = {
        title: "Easy Go",
        desc: "EasyGo is a Lifestyle payment app that provides easy payments for basic human needs, we provide most of the basic utility services. ",
        desc2: "EasyGo makes payment easy, secure and reliable. Creating a new and unique experience for customers through our automated bill payment system. Therefore changing culture around how bills are paid among our audience and Africa.",
        appLink:{
            playstore: "https://lnkd.in/gyVPJVXc",
            appstore: "https://lnkd.in/gdDSfGjr",
        },
        // webLink: 'https://easygo.com.ng/',
        thumbnail: "/images/banners/easygoBanner.png",
        video: "/vids/easygo.mp4"
    }
  return (
    <div className="">
        <Navbar/>
        <div className="p-8">
            <SingleProductCard product={product}/>
        </div>
        <div className="text-center mb-12">
            <h1 className="text-lg font-bold text-violet-700">Why Easy Go</h1>
            <p>Here are some of our advantages</p>
        </div>
        <div className="mb-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-center gap-10">
            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Lifestyle and Payment App" className="w-8 mb-2" src="/images/icons/cable.png"/>
                <h1>Go Cable</h1>
                <p>
                    You can pay for subscription for your DSTV, GOTV, STARTIMES, NETFLIX etc
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Lifestyle and Payment App" className="w-8 mb-2" src="/images/icons/elect.png"/>
                <h1>Go Power</h1>
                <p>
                Making payments for electicity just got easy with Easygo
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Lifestyle and Payment App" className="w-8 mb-2" src="/images/icons/data.png"/>
                <h1>Go Data and Go Airtime</h1>
                <p>
                Recharge airtime and data with no stress at all
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Lifestyle and Payment App" className="w-8 mb-2" src="/images/icons/cable.png"/>
                <h1>Go Religious</h1>
                <p>
                Religious organizations on Easygo can receive donations, offerings and tithe from registered users
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Lifestyle and Payment App" className="w-8 mb-2" src="/images/icons/elect.png"/>
                <h1>Go Transfer</h1>
                <p>
                This Feature enables wallet transfer between registered users
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Lifestyle and Payment App" className="w-8 mb-2" src="/images/icons/data.png"/>
                <h1>Go Booking</h1>
                <p>
                Book flight, hotel and Transport services from the application
                </p>
            </div>
            </div>

        </div>
        <CTASection/>
        <Footer/>
    </div>
  );
}
