import { Carousel } from 'antd';
import React from 'react';
import SliderBG from '../img/SliderBG.png'
const contentStyle = {
  minHeight: '100vh',
  padding: '1rem',
  backgroundImage: "url('/images/bg/SliderBG.png')"
};

export default function HomeCarousel() {

  return (
    // <SiteCarousel/>
    <Carousel autoPlaySpeed={2000} autoplay>

    <section>
    <div className='bg-slate-200 p-4 flex justify-center flex-col lg:flex-row lg:justify-between items-center'>
      <div className='lg:w-1/2 p-4 flex justify-center slide-txt'>
        <div className='w-full text-center lg:text-start mb-8 lg:mb-0'>
          <h1 className='lg:w-full'>
          <span className='text-3xl lg:text-5xl slide-title'>
          Excellence In <span className='text-optiorange'>Technology.</span>
          </span>
          </h1>
          <p className='w-full text-md lg:text-lg slide-desc lg:w-4/5'>
          Need custom process automation, software development, tech outsourcing, consulting services and software solutions in Nigeria?
          </p>
          <a href='/contact' className="text-white ">
            <button className='w-40 rounded-md p-2 bg-optiblue text-white'>
              Get in Touch
            </button>
          </a>
        </div>
      </div>

      <div className='lg:w-1/2 lg:relative mt-4 lg:mt-0flex justify-center'>
        <img className="w-4/5" src='/images/banners/carousel.png' alt="Software Development In Lagos"/>
      </div>
    </div>
    </section>

    <section>
    <div className='bg-slate-200 p-4 flex justify-center flex-col lg:flex-row lg:justify-between items-center'>
      <div className='lg:w-1/2 p-4 flex justify-center slide-txt'>
        <div className='w-full text-center lg:text-start mb-8 lg:mb-0'>
          <h1 className='lg:w-full'>
          <span className='text-3xl lg:text-5xl slide-title'>
          Easy Lifestyle,  <span className='text-optiorange'>Choose EasyGo.</span>
          </span>
          </h1>
          <p className='w-full text-md lg:text-lg slide-desc lg:w-4/5'>
          Make secure, quick and easy payments for your utility bills conveniently from the comfort of your mobile device using this Lifestyle app.
          </p>
          <a href='/easygo' className="text-white ">
            <button className='w-40 rounded-md p-2 bg-optiblue text-white'>
              Learn More on EasyGo
            </button>
          </a>
        </div>
      </div>

      <div className='lg:w-1/2 lg:relative mt-4 lg:mt-0flex justify-center'>
        <img className="w-4/5" src='/images/banners/carousel1.png' alt="Software Development In Lagos"/>
      </div>
    </div>
    </section>


    <section>
    <div className='bg-slate-200 p-4 flex justify-center flex-col lg:flex-row lg:justify-between items-center'>
      <div className='lg:w-1/2 p-4 flex justify-center slide-txt'>
        <div className='w-full text-center lg:text-start mb-8 lg:mb-0'>
          <h1 className='lg:w-full'>
          <span className='text-3xl lg:text-5xl slide-title'>
          Automate Your Business Processes For Productivity, <span className='text-optiorange'>use E-Zone</span>
          </span>
          </h1>
          <p className='w-full text-md lg:text-lg slide-desc lg:w-4/5'>
          Ezone ERP software is built for small, medium, and large businesses looking to streamline and automate their core business processes.
          </p>
          <a href='/ezone' className="text-white ">
            <button className='w-40 rounded-md p-2 bg-optiblue text-white'>
              Learn More on E-Zone
            </button>
          </a>
        </div>
      </div>

      <div className='lg:w-1/2 lg:relative mt-4 lg:mt-0flex justify-center'>
        <img className="w-4/5" src='/images/banners/carousel2.png' alt="Software Development In Lagos"/>
      </div>
    </div>
    </section>

    <section>
    <div className='bg-slate-200 p-4 flex justify-center flex-col lg:flex-row lg:justify-between items-center'>
      <div className='lg:w-1/2 p-4 flex justify-center slide-txt'>
        <div className='w-full text-center lg:text-start mb-8 lg:mb-0'>
          <h1 className='lg:w-full'>
          <span className='text-3xl lg:text-5xl slide-title'>
          <span className='text-optiorange'>Realm</span>, your Properties In Your Hand.
          </span>
          </h1>
          <p className='w-full text-md lg:text-lg slide-desc lg:w-4/5'>
          Manage your properties/facilities from anywhere with this integrated property management system that can collect rent, send invoce, track maintenance requests, amongst other things.
          </p>
          <a href='/realm' className="text-white ">
            <button className='w-40 rounded-md p-2 bg-optiblue text-white'>
              Learn More on Realm
            </button>
          </a>
        </div>
      </div>

      <div className='lg:w-1/2 lg:relative mt-4 lg:mt-0flex justify-center'>
        <img className="w-4/5" src='/images/banners/carousel3.png' alt="Software Development In Lagos"/>
      </div>
    </div>
    </section>






    <section>
    <div className='bg-slate-200 p-4 flex justify-center flex-col lg:flex-row lg:justify-between items-center'>
      <div className='lg:w-1/2 p-4 flex justify-center slide-txt'>
        <div className='w-full text-center lg:text-start mb-8 lg:mb-0'>
          <h1 className='lg:w-full'>
          <span className='text-3xl lg:text-5xl slide-title'>
          Think Inventory, <span className='text-optiorange'>Think Cube700.</span>
          </span>
          </h1>
          <p className='w-full text-md lg:text-lg slide-desc lg:w-4/5'>
          Are you looking to automate your inventory management process?  <br/><br/>
          This easy-to-use system can easily track the inventory of your products, record sales, track expenses, and generate reports.
          </p>
          <a href='/cube' className="text-white ">
            <button className='w-40 rounded-md p-2 bg-optiblue text-white'>
              Learn More on Cube700
            </button>
          </a>
        </div>
      </div>

      <div className='lg:w-1/2 lg:relative mt-4 lg:mt-0flex justify-center'>
        <img className="w-4/5" src='/images/banners/carousel5.png' alt="Software Development In Lagos"/>
      </div>
    </div>
    </section>

    <section>
    <div className='bg-slate-200 p-4 flex justify-center flex-col lg:flex-row lg:justify-between items-center'>
      <div className='lg:w-1/2 p-4 flex justify-center slide-txt'>
        <div className='w-full text-center lg:text-start mb-8 lg:mb-0'>
          <h1 className='lg:w-full'>
          <span className='text-3xl lg:text-5xl slide-title'>
          Take Control of Your Financial Activities <span className='text-optiorange'>with Finman</span>
          </span>
          </h1>
          <p className='w-full text-md lg:text-lg slide-desc lg:w-4/5'>
          Finman is a core banking solution for MFBs and MFIs with an integrated customers mobile app for loans, investments and savings
          </p>
          <a href='/finman' className="text-white ">
            <button className='w-40 rounded-md p-2 bg-optiblue text-white'>
              Learn More on Finman
            </button>
          </a>
        </div>
      </div>

      <div className='lg:w-1/2 lg:relative mt-4 lg:mt-0flex justify-center'>
        <img className="w-4/5" src='/images/banners/carousel6.png' alt="Software Development In Lagos"/>
      </div>
    </div>
    </section>

    <section>
    <div className='bg-slate-200 p-4 flex justify-center flex-col lg:flex-row lg:justify-between items-center'>
      <div className='lg:w-1/2 p-4 flex justify-center slide-txt'>
        <div className='w-full text-center lg:text-start mb-8 lg:mb-0'>
          <h1 className='lg:w-full'>
          <span className='text-3xl lg:text-5xl slide-title'>
          Your Tech Goal,<span className='text-optiorange'> Our Objective.</span>
          </span>
          </h1>
          <p className='w-full text-md lg:text-lg slide-desc lg:w-4/5'>
          Do you have a one million dollar tech product idea? We will deliver a state of the art software solution and innovative mobile apps for you using
          the latest technologies
          </p>
          <a href='/contact' className="text-white ">
            <button className='w-40 rounded-md p-2 bg-optiblue text-white'>
              Get in Touch
            </button>
          </a>
        </div>
      </div>

      <div className='lg:w-1/2 lg:relative mt-4 lg:mt-0flex justify-center'>
        <img className="w-4/5" src='/images/banners/carousel7.png' alt="Software Development In Lagos"/>
      </div>
    </div>
    </section>

  </Carousel>
)}


// function SiteCarousel() {

//   return (

// )}
