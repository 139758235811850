const bannerStyle = {
    backgroundImage: "url('/images/bg/faq.jpg')",
    backgroundSize: "cover"
}
export default function FAQBanner() {
  return (
    <div style={bannerStyle} className="h-96 flex justify-center items-center">
      <h1 className="text-optiblue font-bold text-2xl p-4 text-center lg:text-4xl">Frequently Asked Questions (FAQs)</h1>
    </div>
  );
}

