import { useEffect, useState, useRef } from "react";
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space, Modal } from 'antd';
import './style.css'
import ReCAPTCHA  from "react-google-recaptcha"
import emailjs from '@emailjs/browser';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  const [screenMobile, setScreenMobile] = useState(false)
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const captchaRef = useRef(null)

  const form = useRef();
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents default refresh by the browser
    // console.log(form.current.project.value )
    if(form.current.name.value !== '' && form.current.email.value !== '' 
    && form.current.phone.value !== '' && form.current.project.value !== '' && form.current.description.value !== ''){
      emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_QUOTE_TEMPLATE_ID, form.current , process.env.REACT_APP_USER_ID)
      .then((result) => {
      alert("Quote Sent, We will get back to you shortly");
      },
      (error) => {
      alert("An error occurred, Please try again", error.text);
      });
    }else{
      alert("All Fields are Required Before Submission");
    }
    
    };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const screenSize = window.innerWidth
    if(screenSize <= 780){
      setScreenMobile(true)
    }
  }, [])

  const handleMobileNav = () =>{
    setMobileNavOpen(!mobileNavOpen)
  }


  const products = (
    <Menu
    items={[
      {
        label: <a href="/easygo">Easy GO</a>,
        key: '0',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="/cube">Cube 700</a>,
        key: '1',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="/ezone">E-ZONE</a>,
        key: '2',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="/spydov">Spydov</a>,
        key: '3',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="/finman">Finman</a>,
        key: '4',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="/realm">Realm</a>,
        key: '5',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="/work400">Work400</a>,
        key: '5',
      },
    ]}
  />
  )

  const services = (
    <Menu
    items={[
      {
        label: <a href="/software">Software Development</a>,
        key: '0',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="/mobile">Mobile Application Design</a>,
        key: '1',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="/digital">Digital Marketing</a>,
        key: '2',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="/it">IT Outsourcing And Consulting</a>,
        key: '3',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="/web">Web And Ecommerce Development</a>,
        key: '4',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="/product">Product Design</a>,
        key: '5',
      },
      {
        type: 'divider',
      },
      {
        label: <a href="https://school.optisoft.ng">Training</a>,
        key: '6',
      },
    ]}
  />
  )

const navlinks = [
{
    title: "Home",
    link: "/"
},
{
    title: "Products",
    link: "/",
    sublinks: products
},
{
    title: "Services",
    link: "/",
    sublinks: services
},
{
    title: "About Us",
    link: "/about"
},
{
    title: "Contact",
    link: "/contact"
},
{
    title: "Blog",
    link: "https://blog.optisoft.ng/"
}
]


  return (
    <nav className="navbar p-4">
      <div className=" flex justify-between items-center">
        <div className="navbarBrand w-1/2">
          <img className="w-25" src="/images/icons/brand.png" alt="Software Development In Lagos"/>
        </div>
        <div className="w-4/5">
          {screenMobile !== true?
              <div className="flex justify-between items-center font-sans font-semibold">
                {navlinks.map(navlink =>{
                if(navlink.sublinks){
                    return(
                    <Dropdown key={navlink.title} overlay={navlink.sublinks}>
                      <div className="text-black hover:text-optiblue">
                        <Space>
                          {navlink.title}
                          <DownOutlined className="text-tiny"/>
                        </Space>
                      </div>
                    </Dropdown>
                  )
                }else{
                  return(
                    <a key={navlink.title} href={navlink.link} className="text-black hover:text-optiblue">{navlink.title}</a>
                  )
                  }
                })}



                <div className="flex items-center">
                  <a href='https://api.whatsapp.com/send?phone=2347088957111' className="hover:scale-125 mr-2">
                      <img className="w-5" src="/images/icons/whatsappb.png" alt="Software Development In Lagos"/>
                  </a>
                  <a href='https://www.linkedin.com/company/optisoft-solutions/' className="hover:scale-125 mr-2">
                      <img className="w-5" src="/images/icons/linkedinb.png" alt="Software Development In Lagos"/>
                  </a>
                  <a href=' https://instagram.com/optisoftng?igshid=YmMyMTA2M2Y=' className="hover:scale-125 mr-2">
                      <img className="w-5" src="/images/icons/instab.png" alt="Software Development In Lagos"/>
                  </a>
                  <a href="tel:+2347088957111" className="hover:scale-125 mr-2">
                    <img className="w-5" src="/images/icons/telephone.png" alt="Software Development In Lagos" />
                  </a>
                </div>
                <div onClick={showModal} className="text-white bg-optiblue  w-40 text-center p-2 rounded-md">
                  <button className="text-white">Get a quote</button>
                </div>
              </div>
            :
            <div className="p-2 flex justify-between items-center">
              <div className="flex items-center">
                  <a href='https://api.whatsapp.com/send?phone=2347088957111' className="hover:scale-125 mr-2">
                      <img className="w-5" src="/images/icons/whatsappb.png" alt="Software Development In Lagos"/>
                  </a>
                  <a href='https://www.linkedin.com/company/optisoft-solutions/' className="hover:scale-125 mr-2">
                      <img className="w-5" src="/images/icons/linkedinb.png" alt="Software Development In Lagos"/>
                  </a>
                  <a href=' https://instagram.com/optisoftng?igshid=YmMyMTA2M2Y=' className="hover:scale-125 mr-2">
                      <img className="w-5" src="/images/icons/instab.png" alt="Software Development In Lagos"/>
                  </a>
                  <a href="tel:+2347088957111" className="hover:scale-125 mr-2">
                    <img className="w-5" src="/images/icons/telephone.png" alt="Software Development In Lagos" />
                  </a>
              </div>
              <MenuOutlined className="text-xl" onClick={handleMobileNav}/>
            </div>
          }
        </div>
      </div>
      {screenMobile === true?
      <div className={classNames(mobileNavOpen === true?"mobileNavOpen":"mobileNavClosed", `mobileNav w-full flex flex-col justify-between items-center font-sans font-semibold bg-slate-50 p-4`)}>
        {navlinks.map(navlink =>{
        if(navlink.sublinks){
            return(
            <Dropdown key={navlink.title} overlay={navlink.sublinks}  trigger={['click']}>
              <div className="mb-4 text-md text-black hover:text-optiblue" onClick={(e) => e.preventDefault()}>
                <Space>
                  {navlink.title}
                  <DownOutlined className="text-tiny"/>
                </Space>
              </div>
            </Dropdown>
          )
        }else{
          return(
            <a key={navlink.title} href={navlink.link} className="mb-4 text-md text-black hover:text-optiblue">{navlink.title}</a>
          )
          }
        })}
      </div>
    :null}

    {screenMobile === true?
      <div className="flex justify-center items-center mt-5">
        <div onClick={showModal} className="font-bold text-white w-40 text-center bg-optiblue p-2 rounded-md">
          <button className="text-white">Get a quote</button>
        </div>
      </div>
    :null}

      <Modal footer={null} title="Get A Quote" open={isModalOpen} onCancel={handleCancel}>
        <div className="p-4">
            <div className="w-full p-2">
                <div className="mb-8 text-center lg:text-start">
                    <h5 className="text-lg font-bold">Get In Touch, Your Idea Plus Our Skills Will bring An Excellent Result </h5>
                </div>
                <form ref={form}>
                <div className="mb-8">
                    <h5 className="text-xs text-gray-400">Your Name</h5>
                    <input className="w-full border border-gray-400 p-1" type='text' name="name"/>
                </div>
                <div className="mb-8">
                    <h5 className="text-xs text-gray-400">Email Address</h5>
                    <input className="w-full border border-gray-400 p-1" type='email' name="email"/>
                </div>
                <div className="mb-8">
                    <h5 className="text-xs text-gray-400">Phone Number</h5>
                    <input className="w-full border border-gray-400 p-1" type='text' name="phone"/>
                </div>
                <div className="mb-8">
                    <h5 className="text-xs text-gray-400">Project Type</h5>
                    <select className="w-full border border-gray-400 p-1" name="project">
                      <option value="Mobile Application Development">Mobile Application Development</option>
                      <option value="Software Development">Software Development</option>
                      <option value="Product Design">Product Design</option>
                      <option value="Web and Ecommerce Development">Web and Ecommerce Development</option>
                      <option value="Digital Marketing">Digital Marketing</option>
                      <option value="IT Outsourcing and Consulting">IT Outsourcing and Consulting</option>
                      <option value="Others">Others</option>
                    </select>
                </div>
                {/* <div className="mb-8">
                    <h5 className="text-xs text-gray-400">Product Type</h5>
                    <select className="w-full border border-gray-400 p-1" name="product">
                      <option value="EZone">E-Zone</option>
                      <option value="Cube700">Cube 700</option>
                      <option value="EasyGo">Easy Go</option>
                      <option value="Spydov">Spydov</option>
                      <option value="Finman">Finman</option>
                      <option value="Realm">Realm</option>
                      <option value="Others">Others</option>
                    </select>
                </div> */}
                <div className="mb-8">
                    <h5 className="text-xs text-gray-400">Brief Description</h5>
                    <textarea className="w-full border border-gray-400 p-1" rows='4' name="description">

                    </textarea>
                </div>

                <div className="flex justify-end">
                  <ReCAPTCHA  sitekey="6LfrHI8iAAAAAGjLllWA5nAARqDflaakAnoGvCOD" ref={captchaRef} />
                </div>

                <div className="flex justify-end mt-8">
                    <button onClick={(e) => {
                      e.preventDefault()
                      setIsModalOpen(false)}} className="w-20 border border-optiblue p-2 text-optiblue rounded-md mr-8">Cancel</button>
                    <button onClick={handleSubmit}  className="w-20 bg-optiblue p-2 text-white rounded-md">Submit</button>
                </div>
                </form>
            </div>
        </div>
      </Modal>
    </nav>
  );
}
