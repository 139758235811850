
import SingleProductCard from './components/SingleProductCard'
import Navbar from "../../globalComponents/Navbar";
import CTASection from '../Home/components/CTASection'
import Footer from "../../globalComponents/Footer";

export default function Finman() {
    const product = {
        title: "Finman",
        webLink: 'https://finman.live',
        desc: "Finman is a cloud-based SAAS solution built after a review of existing core banking software solutions in the Nigerian market.",
        desc2: "Finman is resilient & easy to use, with a robust backend engine built to support banking and financial services ranging from small to commercial banking operations.",
        image: "/images/banners/finman.png"
    }
  return (
    <div className="">
        <Navbar/>
        <div className="p-8">
            <SingleProductCard product={product}/>
        </div>
        <div className="text-center mb-12">
            <h1 className="text-lg font-bold text-violet-700">Why Finman</h1>
            <div className="flex justify-center">
              <p className="w-1/2">Finman is a reliable core banking solution targeted at MFBs and MFIs. It allows for the seamless processing of daily banking transactions and can be configured or customised to the client’s specification. </p>
            </div>
        </div>
        <div className="mb-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-center items-center gap-10">
            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Cloud Based SAAS System" className="w-8 mb-2" src="/images/icons/data.png"/>
                <h1>Account Management</h1>
                <p>
                This module is designed to support financial institutions with managing their financial transactions. It supports payment authorization holds, overdrafts, payment orders, and carries out accounting functions working closely with card, loan and investment management applications. It is a valuable tool for financial institutions looking to improve their efficiency rate and ensure accuracy in managing their finances.
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Cloud Based SAAS System" className="w-8 mb-2" src="/images/icons/cable.png"/>
                <h1>Loan management</h1>
                <p>
                  This module automates all types of loan processes through the whole transaction cycle from request to disbursements until termination. The system provides comprehensive effective toolsets for the Micro Finance Bank (MFB) and Micro Finance Institute (MFI) administrative tasks and manages all the transactions related to the loan.
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Cloud Based SAAS System" className="w-8 mb-2" src="/images/icons/data.png"/>
                <h1>Admin Management</h1>
                <p>
                  This module contains sub-modules that allows the admin to manage their institution’s tool sets such as branch teller, interest rate, Manage Users, Manage Roles, Role Rights and Assign User Rights. These functions and more can be managed or modified when necessary.
                </p>
            </div>
            </div>

            <div className="flex justify-center">
            <div className="w-60 flex flex-col justify-center items-center text-center p-2">
                <img alt="Cloud Based SAAS System" className="w-8 mb-2" src="/images/icons/elect.png"/>
                <h1>Customer Service Management</h1>
                <p>
                  This module is designed to handle customer accounts and customer sub-modules. Here, deposit and saving accounts can be created for users, edited and modified. It also supports a full range of interest and fee calculation options
                </p>
            </div>
            </div>

        </div>
        <CTASection/>
        <Footer/>
    </div>
  );
}
